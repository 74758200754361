import { useState, useCallback } from 'react'

import { Tratamientos } from 'services'

const useTratamientos = () => {
  const [loading, setLoading] = useState(false)

  const handleGetTratamientos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getTratamientos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetTratamiento = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getTratamiento(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddTratamiento = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addTratamiento(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateTratamiento = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.updateTratamiento(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDrogasRel = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getDrogasRel(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDrogasRel = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addDrogasRel(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleRemoveDrogaRel = useCallback(async (id, id_droga_rel) => {
    try {
      setLoading(true)
      const response = await Tratamientos.removeDrogaRel(id, id_droga_rel)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddTratamiento01 = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addTratamiento01(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEsquemaRealizadoInicial = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getEsquemaRealizadoInicial(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEsquemaRealizadoActual = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getEsquemaRealizadoActual(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEsquemaRealizadoSeguimiento = useCallback(async (id, id_seguimiento, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getEsquemaRealizadoSeguimiento(id, id_seguimiento, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddEsquemaRealizado = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addEsquemaRealizado(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDetalleEsquemaRealizado = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addDetalleEsquemaRealizado(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddEsquemaRealizadoDetalle = useCallback(async (id, id_esquema_realizado, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addEsquemaRealizadoDetalle(id, id_esquema_realizado, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDetalleEsquemaRealizadoSeguimiento = useCallback(async (id, id_seguimiento, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addDetalleEsquemaRealizadoSeguimiento(id, id_seguimiento, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDetalleEsquemaRealizado = useCallback(async (id_tratamiento, id_esquema_realizado, id_detalle) => {
    try {
      setLoading(true)
      const response = await Tratamientos.deleteDetalleEsquemaRealizado(id_tratamiento, id_esquema_realizado, id_detalle)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEstudios = useCallback(async (id_tratamiento, id_seguimiento, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getEstudios(id_tratamiento, id_seguimiento, params);

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddEstudio = useCallback(async (id_tratamiento, id_seguimiento, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addEstudio(id_tratamiento, id_seguimiento, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleRemoveEstudio = useCallback(async (id, id_tratamiento, id_seguimiento) => {
    try {
      setLoading(true)
      const response = await Tratamientos.removeEstudio(id, id_tratamiento, id_seguimiento)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSeguimientos = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getSeguimientos(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddSeguimiento = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.addSeguimiento(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateSeguimiento = useCallback(async (id, id_seguimiento, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.updateSeguimiento(id, id_seguimiento, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAuditarTratamiento = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Tratamientos.auditarTratamiento(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAutorizarTratamiento = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Tratamientos.autorizarTratamiento(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleFinalizarTratamiento = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.finalizarTratamiento(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleTratamientoPendienteMedico = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.tratamientoPendienteMedico(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAnularTratamiento = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Tratamientos.anularTratamiento(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetTiposTratamiento = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getTiposTratamiento(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEstadificaciones = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getEstadificaciones(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetTratamientoEventos = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getTratamientoEventos(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetReporteTratamientos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Tratamientos.getReporteTratamientos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetTratamientos,
    handleGetTratamiento,
    handleAddTratamiento,
    handleUpdateTratamiento,
    handleGetDrogasRel,
    handleAddDrogasRel,
    handleRemoveDrogaRel,
    handleAddTratamiento01,
    handleGetEsquemaRealizadoInicial,
    handleGetEsquemaRealizadoActual,
    handleGetEsquemaRealizadoSeguimiento,
    handleAddEsquemaRealizado,
    handleAddDetalleEsquemaRealizado,
    handleAddEsquemaRealizadoDetalle,
    handleAddDetalleEsquemaRealizadoSeguimiento,
    handleDeleteDetalleEsquemaRealizado,
    handleGetEstudios,
    handleAddEstudio,
    handleRemoveEstudio,
    handleGetSeguimientos,
    handleAddSeguimiento,
    handleUpdateSeguimiento,
    handleAuditarTratamiento,
    handleAutorizarTratamiento,
    handleFinalizarTratamiento,
    handleTratamientoPendienteMedico,
    handleAnularTratamiento,
    handleGetTiposTratamiento,
    handleGetEstadificaciones,
    handleGetTratamientoEventos,
    handleGetReporteTratamientos,
  }
}

export default useTratamientos;