/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

import routes from 'app/routing/routes'

const SidebarMenuMain: FC = () => {
const intl = useIntl()

return (
  <>
    <SidebarMenuItem
      to={routes.DASHBOARD}
      icon='element-11'
      title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      fontIcon='bi-app-indicator'
    />

    <SidebarMenuItem
      to={routes.USERS}
      title={'Usuarios'}
      icon='profile-circle'
      fontIcon='bi-map'
      allowedRoles={["ADMIN"]}
    />

    <SidebarMenuItem
      to={routes.FINANCIADORES}
      title={'Financiadores'}
      icon='brifecase-cros'
      fontIcon='bi-map'
      allowedRoles={["ADMIN"]}
    />

    <SidebarMenuItem
      to={routes.LABORATORIOS}
      title={'Laboratorios'}
      icon='capsule'
      fontIcon='bi-map'
      allowedRoles={["ADMIN"]}
    />

    <SidebarMenuItem
      to={routes.PATIENTS}
      title={'Pacientes'}
      icon='profile-user'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "MEDICO", "AUDITOR"]}
    />

    <SidebarMenuItem
      to={routes.TRATAMIENTOS}
      title={'Tratamientos'}
      icon='check-square'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "MEDICO", "AUDITOR", "LABORATORIO", "DROGUERIA", "FINANCIADOR"]}
    />

    {/* <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS' })}
      icon='profile-user'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "COMPRAS", "VENTAS"]}
    >
      <SidebarMenuItem
        to={routes.CUSTOMER_SUPPLIERS}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS_CUSTOMER_SUPPLIERS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "COMPRAS", "VENTAS"]}
      />

      <SidebarMenuItem
        to={routes.PATIENTS}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS_PATIENTS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "VENTAS"]}
      />

      <SidebarMenuItem
        to={routes.SHIPMENT_ADDRESSES}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS.SHIPMENT_ADDRESSES' })}
        hasBullet
        allowedRoles={[ "ADMIN", "COMPRAS", "VENTAS"]}
      />
    </SidebarMenuItemWithSub> */}
  </>
)
}

export {SidebarMenuMain}