import * as Yup from 'yup'

const tiposDocumento = [
  { value: 'DNI', label: 'DNI' },
  { value: 'CUIT', label: 'CUIT' },
  { value: 'Otro', label: 'Otro' },
]

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
  locality_custom: '',
  custom_address_level1_label: '',
  custom_address_level1: '',
  custom_address_level2_label: '',
  custom_address_level2: '',
}

const pacienteInitialValues = {
  nombre: '',
  apellido: '',
  tipo_documento: '',
  documento: '',
  email: '',
  telefono_fijo: '',
  telefono_movil: '',
  address: googleAddress,
  id_obra_social: '',
  numero_afiliado: '',
}

const pacienteSchema = Yup.object().shape({
  nombre: Yup.string().required('* Ingrese un nombre para el paciente'),
  apellido: Yup.string().required('* Ingrese un apellido para el paciente'),
  tipo_documento: Yup.string().required('* Seleccione un tipo de documento'),
  documento: Yup.string().required('* Ingrese el documento del paciente'),
  email: Yup.string().email('* Ingrese un email válido'),
  telefono_fijo: Yup.string(),
  telefono_movil: Yup.string(),
  id_obra_social: Yup.string().required('* Seleccione una obra social'),
  numero_afiliado: Yup.string(),
})

const pacienteAdapter = (paciente) => {
  let values = {
    ...paciente,
    email: paciente?.email || '',
    telefono_fijo: paciente?.telefono_fijo || '',
    telefono_movil: paciente?.telefono_movil || '',
    numero_afiliado: paciente?.numero_afiliado || '',
  }

  return values
}

const updatePacienteAdapter = (paciente) => {
  let values = {
    ...pacienteAdapter(paciente),
  }
  delete values.obra_social_nombre
  delete values.id_usuario_creacion
  delete values.fecha_creacion
  delete values?.fecha_modificacion

  return values
}

export {
  pacienteInitialValues,
  pacienteSchema,
  pacienteAdapter,
  updatePacienteAdapter,
  tiposDocumento,
}