import service from 'services/service'

const PATH = {
  laboratorios: '/laboratorios',
}

export class Laboratorios {
  static get = async (params) => {
    const response = await service.get(`${PATH.laboratorios}`, {
      params
    })
    return response
  }

  static getLaboratorio = async (id, params) => {
    const response = await service.get(`${PATH.laboratorios}/${id}`, {
      params
    })
    return response
  }

  static add = async (data) => {
    const response = await service.post(`${PATH.laboratorios}`, data)
    return response
  }

  static update = async (id, data) => {
    const response = await service.patch(`${PATH.laboratorios}/${id}`, data)
    return response
  }
}