import { useEffect, useState } from 'react'
import { Modal, FormLabel, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useTratamientos, useDrogas } from 'hooks'

import { InputBS } from 'components'

const EsquemaRealizadoDetalleSeguimientoModal = ({ inicial = false, show, onClose, onSubmit, values }) => {
  const { loadingD, handleGetDrogas } = useDrogas()
  const [drogas, setDrogas] = useState([])

  const getDrogas = async (query) => {
    try {
      let params = {
        activo: 1,
        text: query,
      }
      const response = await handleGetDrogas(params)
      setDrogas(response.data.result)
    } catch (error) {
      toast.error(error.mesage)
      setDrogas([])
    }
  }

  useEffect(() => {
    console.log(values)
  }, [show])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='lg' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de esquema realizado</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            id_droga: '',
            droga_nombre: '',
            dosis: '',
            ciclo_dias: '',
            cantidad_cajas_por_ciclo: '',
            cantidad_ciclos: '',
            total_cajas: '',
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col'>
                    <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Droga</FormLabel>
                    <AsyncTypeahead
                      id='busquedaDroga'
                      isLoading={loadingD}
                      minLength={3}
                      labelKey={(d) => `${d.nombre}`}
                      onSearch={getDrogas}
                      options={drogas}
                      filterBy={() => true}
                      prompText='Escribe para buscar una droga'
                      searchText='Buscando...'
                      placeholder='Escribe para buscar una droga'
                      onChange={(e) => {
                        if (e && e.length > 0) {
                          setFieldValue('id_droga', e[0].id)
                          setFieldValue('droga_nombre', e[0].nombre)
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-4'>
                    <InputBS
                      id='dosis'
                      name='dosis'
                      type='text'
                      label='Dosis'
                      placeholder='Ej: 30mg'
                    />
                  </div>

                  <div className='col-2'>
                    <InputBS
                      id='ciclo_dias'
                      name='ciclo_dias'
                      type='number'
                      label='Ciclo días'
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS
                      id='cantidad_cajas_por_ciclo'
                      name='cantidad_cajas_por_ciclo'
                      type='number'
                      label='Cajas por ciclo'
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS
                      id='cantidad_ciclos'
                      name='cantidad_ciclos'
                      type='number'
                      label='Cantidad de ciclos'
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-10'></div>

                  <div className='col-2'>
                    <InputBS
                      id='total_cajas'
                      name='total_cajas'
                      label='Total cajas'
                      value={
                        (values?.cantidad_cajas_por_ciclo && values.cantidad_cajas_por_ciclo !== '') &&
                        (values?.cantidad_ciclos && values.cantidad_ciclos !== '')
                          ? values?.cantidad_cajas_por_ciclo * values?.cantidad_ciclos
                          : 0
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>
                <div className='text-end'>
                  <Button
                    type='button'
                    variant='secondary'
                    className='me-3'
                    onClick={onClose}
                  >
                    <i className='bi bi-slash-circle' />
                    Cancelar
                  </Button>

                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => {
                      setFieldValue('total_cajas',
                        (values?.cantidad_cajas_por_ciclo && values.cantidad_cajas_por_ciclo !== '') &&
                        (values?.cantidad_ciclos && values.cantidad_ciclos !== '')
                          ? values.cantidad_cajas_por_ciclo * values.cantidad_ciclos
                          : 0
                      )
                      handleSubmit()
                    }}
                  >
                    <i className='bi bi-save me-2' />
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EsquemaRealizadoDetalleSeguimientoModal