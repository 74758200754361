const COLUMNS = [
  {
    Header: 'Droga',
    id: 'droga_nombre',
    accessor: (row) => (
      <>
        {row.droga_nombre}
      </>
    ),
  },
  {
    Header: <div className='text-center'>Dosis</div>,
    id: 'dosis',
    accessor: (row) => (
      <div className="text-center">
        {row.dosis}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Ciclo días</div>,
    id: 'ciclo_dias',
    accessor: (row) => (
      <div className="text-center">
        {row.ciclo_dias}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Cajas por ciclo</div>,
    id: 'cantidad_cajas_por_ciclo',
    accessor: (row) => (
      <div className="text-center">
        {row.cantidad_cajas_por_ciclo}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Cantidad ciclos</div>,
    id: 'cantidad_ciclos',
    accessor: (row) => (
      <div className="text-center">
        {row.cantidad_ciclos}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Total cajas</div>,
    id: 'total_cajas',
    accessor: (row) => (
      <div className="text-center">
        {row.total_cajas}
      </div>
    ),
  },
]

export default COLUMNS