import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useTratamientos, usePatologias, useDiagnosticos, useFinanciadores, useLaboratorios } from 'hooks'
import { Input, SelectField } from 'components'

const status = [
  { value: 1, label: 'Borrador' },
  { value: 2, label: 'Pendiente auditor' },
  { value: 3, label: 'En progreso' },
  { value: 4, label: 'Finalizado OK' },
  { value: 5, label: 'Pendiente médico' },
  { value: 6, label: 'Finalizado No OK' },
]

const Filter = ({ handleData, query, handleQuery, exportData }) => {
  const { handleGetTratamientos, handleGetTiposTratamiento } = useTratamientos()
  const { handleGetFinanciadores } = useFinanciadores()
  const { handleGetLaboratorios } = useLaboratorios()
  const { handleGetPatologias } = usePatologias()
  const { handleGetCie10Cabeceras, handleGetCie10Detalles } = useDiagnosticos()

  const [tipos, setTipos] = useState([])
  const [financiadores, setFinanciadores] = useState([])
  const [laboratorios, setLaboratorios] = useState([])
  const [patologias, setPatologias] = useState([])
  const [cie10Cabeceras, setCie10Cabeceras] = useState([])
  const [cie10Detalles, setCie10Detalles] = useState([])

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      text: values?.text? values.text : undefined,
      id_financiador: values?.id_financiador || undefined,
      id_laboratorio: values?.id_laboratorio || undefined,
      id_tipo_tratamiento_filter: values?.id_tipo_tratamiento_filter || undefined,
      id_estado_tratamiento_filter: values?.id_estado_tratamiento_filter || undefined,
      id_cie10_cabecera: values?.id_cie10_cabecera || undefined,
      id_cie10_cabeceras_filter: values?.id_cie10_cabeceras_filter || undefined,
      id_cie10_detalle: values?.id_cie10_detalle || undefined,
      id_cie10_detalles_filter: values?.id_cie10_detalles_filter || undefined,
      id_patologia_filter: values?.id_patologia_filter || undefined,
    }

    try {
      const response = await handleGetTratamientos(params)

      handleQuery({ ...params, pageNumber: 1 })
      handleData(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getTiposTratamiento = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetTiposTratamiento(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setTipos(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetTratamientos])

  const getFinanciadores = useCallback(async () => {
    try {
      const response = await handleGetFinanciadores()
      let data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      data.unshift({
        value: '',
        label: 'Todos',
      })

      setFinanciadores(data)
    } catch (error) {
      setFinanciadores([])
      toast.error(error.message)
    }
  }, [handleGetFinanciadores])

  const getLaboratorios = useCallback(async () => {
    try {
      const response = await handleGetLaboratorios()
      let data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      data.unshift({
        value: '',
        label: 'Todos',
      })

      setLaboratorios(data)
    } catch (error) {
      setLaboratorios([])
      toast.error(error.message)
    }
  }, [handleGetLaboratorios])

  const getPatologias = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetPatologias(params)
      let data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setPatologias(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPatologias])

  const getCie10Cabeceras = useCallback(async (text = '') => {
    try {
      const params = {
        text: text,
        sortOrder: 'ASC',
      }
      const response = await handleGetCie10Cabeceras(params)
      let data = response.data.result.map(d => ({ value: d.cod_3, label: `${d.cod_3} - ${d.descripcion_3}` }))

      data.unshift({
        value: '',
        label: 'Todos'
      })

      setCie10Cabeceras(data)
    } catch (error) {
      setCie10Cabeceras([])
      toast.error(error.message)
    }
  }, [handleGetCie10Cabeceras])

  const getCie10Detalles = async (cod_3) => {
    try {
      const params = {
        cod_3: cod_3,
        sortOrder: 'ASC',
      }
      const response = await handleGetCie10Detalles(params)
      let data = response.data.result.map(d => ({ value: d.cod_4, label: `${d.cod_4} - ${d.descripcion_4}` }))

      data.unshift({
        value: '',
        label: 'Todos',
      })

      setCie10Detalles(data)
    } catch (error) {
      setCie10Detalles([])
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getTiposTratamiento()
    getFinanciadores()
    getLaboratorios()
    getCie10Cabeceras()
    getPatologias()
  }, [getTiposTratamiento, getFinanciadores, getLaboratorios, getCie10Cabeceras, getPatologias])

  return (
    <Formik
      initialValues={{
        text: '',
        id_tipo_tratamiento_filter: '',
        id_financiador: '',
        id_laboratorio: '',
        id_estado_tratamiento_filter: '',
        id_patologia_filter: '',
        id_cie10_cabecera: '',
        id_cie10_detalle: '',
      }}
      onSubmit={filterData}
    >
      {({ values, errors, setFieldValue }) => (
        <Form className='d-flex flex-column'>
          <div className='row'>

            <div className='col-5'>
              <Input id='text' name='text' />
              <p className='text-muted'>Documento / Paciente</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='id_tipo_tratamiento_filter'
                name='id_tipo_tratamiento_filter'
                options={tipos}
                isMulti
                placeholder='Seleccione un tipo de tratamiento'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Tipo tratamiento</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='id_estado_tratamiento_filter'
                name='id_estado_tratamiento_filter'
                options={status}
                isMulti
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='id_patologia_filter'
                name='id_patologia_filter'
                options={patologias}
                isMulti
                placeholder='Seleccione una patología'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Patología</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='id_cie10_cabecera'
                name='id_cie10_cabecera'
                options={cie10Cabeceras}
                placeholder='Seleccione un diagnóstico'
                handleChange={e => {
                  console.log(e)
                  if (e) {
                    setFieldValue('id_cie10_cabecera', e.value)
                    setFieldValue('id_cie10_detalle', '')
                    getCie10Detalles(e.value)
                  } else {
                    setFieldValue('id_cie10_cabecera', '')
                    setFieldValue('id_cie10_detalle', '')
                    setCie10Detalles([])
                  }
                }}
                marginTop={'-20px'}
              />
              <p className='text-muted'>Diagnóstico</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='id_cie10_detalle'
                name='id_cie10_detalle'
                options={cie10Detalles}
                placeholder='Seleccione un detalle'
                marginTop={'-20px'}
                disabled={values?.id_cie10_cabecera == ''}
              />
              <p className='text-muted'>Detalle</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='id_financiador'
                name='id_financiador'
                options={financiadores}
                placeholder='Seleccione un financiador'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Financiador</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='id_laboratorio'
                name='id_laboratorio'
                options={laboratorios}
                placeholder='Seleccione un laboratorio'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Laboratorio</p>
            </div>
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()}
                className="me-3"
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button variant="primary" type='submit'>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter