import React from 'react'
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup';

import { useAuth } from 'app/modules/auth'
import { useUsuarios } from 'hooks';

import { InputBS } from 'components'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Ingrese una contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(/\d/, 'La contraseña debe contener al menos un número')
    .matches(/[A-Z]/, 'La contraseña debe contener al menos una mayúscula'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Confirme su contraseña'),
});

const ResetPasswordModal = ({ show, onHide, onSubmit, usuario }) => {
  const { auth, saveAuth, setCurrentUser } = useAuth()
  const { id } = useParams()

  if (!show) return null

  return (
    <Modal size='md' show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton={!usuario?.temp_password || (auth.user.usuario_email == usuario.email && !auth.user.usuario_password_temp)}>
        <Modal.Title>Reestablecer contraseña</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            password: '',
            password2: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col'>
                    <InputBS id='password' name='password' type='password' label='Nueva contraseña' />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col'>
                    <InputBS id='password2' name='password2' type='password' label='Confirme la contraseña' />
                  </div>
                </div>

                <div className='separatory my-8'></div>

                <div className='text-end'>
                  <Button type='submit' variant='primary'>Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ResetPasswordModal