import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, usePacientes } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { useGetColumns } from './columns'

import { ROLES } from 'interfaces'
import routes from 'app/routing/routes'

const initialQuery = {
	pageNumber: 1,
	pageSize: 10,
}

const Pacientes = () => {
	const { auth } = useAuth()

	const [data, setData] = useState([])
	const { COLUMNS } = useGetColumns()

	const [query, setQuery] = useState(initialQuery)
	const [totalCount, setTotalCount] = useState(0)

	const { handleGetPacientes } = usePacientes()

	const getPacientes = useCallback(async () => {
		try {
			let params = {}

			if (query && query.pageNumber) {
				params = {
					...params,
					...query,
				}
			}

			const response = await handleGetPacientes(params)
			setData(response.data.result)
			setTotalCount(response.data.metadata.count)
		} catch (error) {
			toast.error(error.message)
			setData([])
			setTotalCount(0)
		}
	}, [handleGetPacientes, query])

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

	/* Menu contextual */
	const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.PATIENTS}/${currentRow?.id}`)
    })

    return actions
  }
  /* Fin menu contextual */

	const paginationOptions = {
		totalSize: totalCount,
		obQuery: query,
		setObQuery: setQuery,
	}

	useEffect(() => {
		getPacientes()
	}, [getPacientes])

	return (
		<>
			<TableContainer
				title={'Pacientes'}
				goTo={auth.user.usuario_roles.some(r => (r.id == ROLES.MEDICO || r.id == ROLES.ADMIN)) && routes.PATIENTS_NEW}
				filter={<Filter handleData={setData} query={query} handleQuery={setQuery} />}
			>
				<CustomTable columns={columns} data={data} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
			</TableContainer>

			<ContextMenu
				showContextMenu={showContextMenu}
				setShowContextMenu={setShowContextMenu}
				actions={availableActions()}
				currentPos={currentPos}
				ref={contextRef}
			/>
		</>
	)
}

export default Pacientes