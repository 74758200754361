import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useTratamientos, useFinanciadores, useLaboratorios, usePacientes, useDiagnosticos, usePatologias } from 'hooks'

import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'

import { tratamientoInitialValues, tratamientoSchema } from '../constants'
import routes from 'app/routing/routes'

const TratamientoCreacion = () => {
  const navigate = useNavigate()
  const submitRef = useRef(null)

  const { loading, handleAddTratamiento, handleGetTiposTratamiento } = useTratamientos()
  const { loading: loadingF, handleGetFinanciadores } = useFinanciadores()
  const { loading: loadingL, handleGetLaboratorios } = useLaboratorios()
  const { loading: loadingP, handleGetPacientes } = usePacientes()
  const { loading: loadingPa, handleGetPatologias } = usePatologias()
  const { loading: loadingD, handleGetDiagnosticos, handleGetCie10Cabeceras, handleGetCie10Detalles } = useDiagnosticos()
  // const [tipos, setTipos] = useState([])
  const [financiadores, setFinanciadores] = useState([])
  const [laboratorios, setLaboratorios] = useState([])
  const [pacientes, setPacientes] = useState([])
  const [cie10Cabeceras, setCie10Cabeceras] = useState([])
  const [cie10Detalles, setCie10Detalles] = useState([])
  const [patologias, setPatologias] = useState([])

  const addTratamiento = async (data) => {
    if (data.id_financiador == '') {
      toast.error('Debe seleccionar un financiador para el tratamiento')
      return
    }
    if (data.id_laboratorio == '') {
      toast.error('Debe seleccionar un laboratorio para el tratamiento')
      return
    }
    if (data.id_paciente == '') {
      toast.error('Debe seleccionar un paciente para el tratamiento')
      return
    }

    try {
      const response = await handleAddTratamiento(data)

      toast.success(response.data.message)
      navigate(`${routes.TRATAMIENTOS}/${response.data.id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  // const getTiposTratamiento = useCallback(async () => {
  //   try {
  //     let params = {
  //       sortOrder: 'ASC',
  //       activo: 1,
  //     }
  //     const response = await handleGetTiposTratamiento(params)
  //     const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

  //     setTipos(data)
  //   } catch (error) {
  //     toast.error(error.message)
  //     setTipos([])
  //   }
  // }, [handleGetTiposTratamiento])

  const getFinanciadores = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetFinanciadores(params)
      const data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      setFinanciadores(data)
    } catch (error) {
      toast.error(error.message)
      setFinanciadores([])
    }
  }, [handleGetFinanciadores])

  const getLaboratorios = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetLaboratorios(params)
      const data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      setLaboratorios(data)
    } catch (error) {
      toast.error(error.message)
      setLaboratorios([])
    }
  }, [handleGetLaboratorios])

  const getPacientes = async (query) => {
    try {
      let params = {
        text: query,
      }
      const response = await handleGetPacientes(params)
      setPacientes(response.data.result)
    } catch (error) {
      toast.error(error.message)
      setPacientes([])
    }
  }

  const getCie10Cabeceras = async (query) => {
    try {
      let params = {
        text: query,
      }
      const response = await handleGetCie10Cabeceras(params)
      setCie10Cabeceras(response.data.result)
    } catch (error) {
      toast.error(error.message)
      setCie10Cabeceras([])
    }
  }

  const getCie10Detalles = async (id_cie10_cabecera) => {
    try {
      let params = {
        cod_3: id_cie10_cabecera,
      }
      const response = await handleGetCie10Detalles(params)
      const data = response.data.result.map(d => ({ value: d.cod_4, label: `${d.cod_4} - ${d.descripcion_4}`}))

      setCie10Detalles(data)
    } catch (error) {
      toast.error(error.message)
      setCie10Detalles([])
    }
  }

  const getPatologias = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
        activo: 1,
      }
      const response = await handleGetPatologias(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setPatologias(data)
    } catch (error) {
      toast.error(error.message)
      setPatologias([])
    }
  }, [handleGetPatologias])

  useEffect(() => {
    // getTiposTratamiento()
    getFinanciadores()
    getLaboratorios()
    getPatologias()
  }, [/* getTiposTratamiento,*/ getFinanciadores, getLaboratorios, getPatologias])

  return (
    <DetailContainer
      title={'Crear tratamiento'}
      buttons={
        <div className='d-flex align-items-center justify-content-end'>
          <Button type='button' variant='secondary' onClick={() => navigate(-1)} className='me-3' disabled={loading}><i className="ki-duotone ki-left" />Volver</Button>
          <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
        </div>
      }
    >
      <Formik
        initialValues={tratamientoInitialValues}
        validationSchema={tratamientoSchema}
        onSubmit={addTratamiento}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <div className='row my-8'>
                {/* <div className='col-4'>
                  <SelectField
                    id='id_tipo_tratamiento'
                    name='id_tipo_tratamiento'
                    label='Tipo tratamiento'
                    options={tipos}
                    placeholder='Seleccionar tipo tratamiento'
                  />
                </div> */}

                <div className='col'>
                  <SelectField
                    id='id_financiador'
                    name='id_financiador'
                    label='Financiador'
                    options={financiadores}
                    placeholder='Seleccione un financiador'
                  />
                </div>

                <div className='col'>
                  <SelectField
                    id='id_laboratorio'
                    name='id_laboratorio'
                    label='Laboratorio'
                    options={laboratorios}
                    placeholder='Seleccione un laboratorio'
                  />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col'>
                  <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Paciente</FormLabel>
                  <AsyncTypeahead
                    id='busquedaPaciente'
                    isLoading={loadingP}
                    minLength={3}
                    labelKey={(p) => `${p.documento} - ${p.nombre} ${p.apellido}`}
                    onSearch={getPacientes}
                    options={pacientes}
                    filterBy={() => true}
                    prompText='Escribe para buscar un paciente'
                    searchText='Buscando...'
                    placeholder='Escribe para buscar un paciente'
                    onChange={(e) => {
                      if (e && e.length > 0) {
                        setFieldValue('id_paciente', e[0].id)
                      }
                    }}
                  />
                </div>

                <div className='col-3'>
                  <InputBS id='talla' name='talla' type='number' label='Talla (cm)' placeholder='165' />
                </div>

                <div className='col-3'>
                  <InputBS id='peso' name='peso' type='number' label='Peso (kg)' placeholder='65' />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col-3'>
                  <SelectField
                    id='id_patologia'
                    name='id_patologia'
                    label='Patología'
                    options={patologias}
                    placeholder='Seleccionar patología'
                  />
                </div>

                <div className='col'>
                  <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Diagnóstico</FormLabel>
                  <AsyncTypeahead
                    id='busquedaCie10Cabecera'
                    isLoading={loadingD}
                    minLength={3}
                    labelKey={(d) => `${d.cod_3} - ${d.descripcion_3}`}
                    onSearch={getCie10Cabeceras}
                    options={cie10Cabeceras}
                    filterBy={() => true}
                    prompText='Escribe para buscar un diagnóstico'
                    searchText='Buscando...'
                    placeholder='Escribe para buscar un diagnóstico'
                    onChange={(e) => {
                      if (e && e.length > 0) {
                        setFieldValue('id_cie10_cabecera', e[0].cod_3)
                        setFieldValue('id_cie10_detalle', '')
                        getCie10Detalles(e[0].cod_3)
                      } else {
                        setFieldValue('id_cie10_cabecera', '')
                        setFieldValue('id_cie10_detalle', '')
                      }
                    }}
                  />
                </div>

                <div className='col'>
                  <SelectField
                    id='id_cie10_detalle'
                    name='id_cie10_detalle'
                    label='Detalle'
                    options={cie10Detalles}
                    placeholder='Seleccionar detalle'
                    disabled={cie10Detalles.length == 0}
                  />
                </div>
              </div>

              <div className='row mt-8'>
                <div className='col-3'></div>
                <div className='col'></div>

                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <InputBS id='fecha_inicio_enfermedad' name='fecha_inicio_enfermedad' type='date' label='Inicio enfermedad' />
                    </div>

                    <div className='col'>
                      <InputBS id='fecha_creacion' name='fecha_creacion' type='date' label='Fecha carga' readOnly disabled />
                    </div>
                  </div>
                </div>
              </div>

              <div className='separator my-8' />

              <div className='text-end'>
                <Button ref={submitRef} type='submit' variant='primary' disabled={loading}>Guardar</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default TratamientoCreacion