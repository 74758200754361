import service from 'services/service'

const PATH = {
  diagnosticos: '/diagnosticos',
  cie10_cabeceras: '/diagnosticos/cie10-cabeceras',
  cie10_detalles: '/diagnosticos/cie10-detalles',
}

export class Diagnosticos {
  static getDiagnosticos = async (params) => {
    const response = await service.get(`${PATH.diagnosticos}`, {
      params,
    })
    return response
  }

  static getDiagnostico = async (id, params) => {
    const response = await service.get(`${PATH.diagnosticos}/${id}`, {
      params
    })
    return response
  }

  static addDiagnostico = async (data) => {
    const response = await service.post(`${PATH.diagnosticos}`, data)
    return response
  }

  static updateDiagnostico = async (id, data) => {
    const response = await service.patch(`${PATH.diagnosticos}/${id}`, data)
    return response
  }

  static getCie10Cabeceras = async (params) => {
    const response = await service.get(`${PATH.cie10_cabeceras}`, {
      params
    })
    return response
  }

  static getCie10Cabecera = async (id) => { // id = cod_3
    const response = await service.get(`${PATH.cie10_cabeceras}/${id}`)
    return response
  }

  static getCie10Detalles = async (params) => {
    const response = await service.get(`${PATH.cie10_detalles}`, {
      params
    })
    return response
  }

  static getCie10Detalle = async (id) => { // id = cod_4
    const response = await service.get(`${PATH.cie10_detalles}/${id}`)
    return response
  }
}