import service from 'services/service'

const PATH = {
  patologias: '/patologias',
}

export class Patologias {
  static getPatologias = async (params) => {
    const response = await service.get(`${PATH.patologias}`, {
      params,
    })
    return response
  }

  static getPatologia = async (id, params) => {
    const response = await service.get(`${PATH.patologias}/${id}`, {
      params
    })
    return response
  }

  static addPatologia = async (data) => {
    const response = await service.post(`${PATH.patologias}`, data)
    return response
  }

  static updatePatologia = async (id, data) => {
    const response = await service.patch(`${PATH.patologias}/${id}`, data)
    return response
  }
}