import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

import { useAuth } from 'app/modules/auth'

import { Maps } from 'pages/Maps/maps'

import { Usuarios, UsuarioCreacion, UsuarioDetalle } from 'pages/Usuarios'
import { Financiadores, FinanciadorCreacion, FinanciadorDetalle } from 'pages/Financiadores'
import { Laboratorios, LaboratorioCreacion, LaboratorioDetalle } from 'pages/Laboratorios'
import { Pacientes, PacienteCreacion, PacienteDetalle } from 'pages/Pacientes'
import { Tratamientos, TratamientoCreacion, TratamientoDetalle } from 'pages/Tratamientos'

import routes from './routes'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const {currentUser} = useAuth();
  const roles = currentUser?.roles;

  useEffect(() => {
    // console.log(currentUser)
    // console.log(roles)
  }, [currentUser])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={routes.DASHBOARD} />} />
        {/* # DASHBOARD # */}
        <Route path={routes.DASHBOARD} element={<DashboardWrapper />} />

        <Route path={routes.USERS} element={<Usuarios />} />
        <Route path={`${routes.USERS}/:id`} element={<UsuarioDetalle />} />
        <Route path={routes.USERS_NEW} element={<UsuarioCreacion />} />

        <Route path={routes.FINANCIADORES} element={<Financiadores />} />
        <Route path={`${routes.FINANCIADORES}/:id`} element={<FinanciadorDetalle />} />
        <Route path={routes.FINANCIADORES_NEW} element={<FinanciadorCreacion />} />

        <Route path={routes.LABORATORIOS} element={<Laboratorios />} />
        <Route path={`${routes.LABORATORIOS}/:id`} element={<LaboratorioDetalle />} />
        <Route path={routes.LABORATORIOS_NEW} element={<LaboratorioCreacion />} />

        <Route path={routes.PATIENTS} element={<Pacientes />} />
        <Route path={`${routes.PATIENTS}/:id`} element={<PacienteDetalle />} />
        <Route path={routes.PATIENTS_NEW} element={<PacienteCreacion />} />

        <Route path={routes.TRATAMIENTOS} element={<Tratamientos />} />
        <Route path={`${routes.TRATAMIENTOS}/:id`} element={<TratamientoDetalle />} />
        <Route path={routes.TRATAMIENTOS_NEW} element={<TratamientoCreacion />} />

        <Route path='maps' element={<Maps />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
