import { useState, useCallback } from 'react'

import { Prestaciones } from 'services'

const usePrestaciones = () => {
  const [loading, setLoading] = useState(false)

  const handleGetPrestaciones = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Prestaciones.getPrestaciones(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPrestacion = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Prestaciones.getPrestacion(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddPrestacion = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Prestaciones.addPrestacion(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePrestacion = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Prestaciones.updatePrestacion(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  // -- PRESTACIONES NOMENCLADOR -- //
  const handleGetPrestacionesNomenclador = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Prestaciones.getPrestacionesNomenclador(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetPrestaciones,
    handleGetPrestacion,
    handleAddPrestacion,
    handleUpdatePrestacion,
    handleGetPrestacionesNomenclador,
  }
}

export default usePrestaciones;