import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'

const Tooltip = ({ text, children }) => {
  return (
    <OverlayTrigger placement='top' overlay={<TooltipBS id='tooltip-top'>{text}</TooltipBS>}>
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip