import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useEstudios } from 'hooks'
import { SelectField, InputBS, TextareaBS } from 'components'

const EstudioModal = ({ show, onClose, onSubmit }) => {
  const { id } = useParams()

  const { loading, handleGetNombresEstudio } = useEstudios()
  const [nombresEstudio, setNombresEstudio] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)

  const getNombresEstudio = useCallback(async () => {
    try{
      let params = {
        activo: 1,
      }
      const response = await handleGetNombresEstudio(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.nombre }))

      setNombresEstudio(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetNombresEstudio])

  useEffect(() => {
    getNombresEstudio()
  }, [getNombresEstudio])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='lg' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Añadir estudio</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            fecha: '',
            id_nombre_estudio: '',
            file: '',
            observacion: '',
          }}
          // validationSchema={{}}
          onSubmit={(values) => {
            onSubmit({ ...values, file: selectedFile })
            setSelectedFile(null)
          }}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-3'>
                    <InputBS id='fecha' name='fecha' type='date' label='Fecha' />
                  </div>

                  <div className='col'>
                    <SelectField
                      id='id_nombre_estudio'
                      name='id_nombre_estudio'
                      options={nombresEstudio}
                      label='Nombre de estudio'
                      placeholder='Seleccione un nombre de estudio'
                    />
                  </div>

                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <InputBS
                          id='file'
                          name='file'
                          type='file'
                          label='Estudio'
                          onChange={e => {
                            setSelectedFile(e.currentTarget.files[0])
                          }}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col'>
                        <span style={{ color: '#8d8c8c' }}>{selectedFile?.name}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-12'>
                    <TextareaBS
                      id='observacion'
                      name='observacion'
                      label='Observación'
                      placeholder='Observación del estudio...'
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>
                <div className='text-end'>
                  <Button
                    type='button'
                    variant='secondary'
                    className='me-3'
                    onClick={() => {
                      onClose()
                    }}
                  >
                    <i className='bi bi-slash-circle' />
                    Cancelar
                  </Button>

                  <Button type='submit' variant='primary'>
                    <i className='bi bi-save me-2' />
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EstudioModal