import { useState, useCallback, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useTratamientos, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { InputBS, SelectField, TextareaBS, CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import EsquemaRealizadoSeguimientoModal from './EsquemaRealizadoSeguimientoModal'
import EsquemaRealizadoDetalleSeguimientoModal from './EsquemaRealizadoDetalleSeguimientoModal'
import COLUMNS from './EsquemaRealizadoColumns'

import { seguimientoInitialValues, respuestas } from '../constants'
import { ESTADO_TRATAMIENTO, ROLES } from 'interfaces'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const SeguimientoModal = ({ values, id_estado_tratamiento, show, onClose, onSubmit, refresh }) => {
  const { id } = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [initialValues, setInitialValues] = useState(seguimientoInitialValues)

  const [esquema, setEsquema] = useState(false)
  const [detalle, setDetalle] = useState(false)

  const {
    handleGetSeguimientos,
    handleUpdateSeguimiento,
    handleAutorizarTratamiento,
    handleTratamientoPendienteMedico,
    handleAnularTratamiento,
    handleFinalizarTratamiento,
    handleGetEsquemaRealizadoSeguimiento,
    handleAddEsquemaRealizado,
    handleAddDetalleEsquemaRealizadoSeguimiento,
    handleDeleteDetalleEsquemaRealizado,
  } = useTratamientos()
  const [seguimientos, setSeguimientos] = useState([])
  const [esquemaRealizado, setEsquemaRealizado] = useState({
    detalle: [],
    observaciones: '',
  })

  const getSeguimientos = useCallback(async () => {
    try {
      const response = await handleGetSeguimientos(id)
      setSeguimientos(response.data.result)
    } catch (error) {
      toast.error(error.message)
      setSeguimientos([])
    }
  }, [handleGetSeguimientos, id])

  const getEsquemaRealizado = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC',
        sortField: 'id',
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await handleGetEsquemaRealizadoSeguimiento(id, values.id, params)
      let data = response.data.result
      delete data.metadata

      setEsquemaRealizado(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setEsquemaRealizado({
        detalle: [],
        observaciones: ''
      })
      setTotalCount(0)
    }
  }, [handleGetEsquemaRealizadoSeguimiento, id, values.id, query])

  const addEsquemaRealizado = async (data) => {
    try {
      const values = {
        ...data,
      }
      const response = await handleAddEsquemaRealizado(values.id, values)

      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      onClose()
      refresh()
    }
  }

  const deleteDetalleEsquemaRealizado = async (id_esquema_realizado, id_detalle) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere eliminar el detalle #${id_detalle} del esquema #${id_esquema_realizado}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, eliminar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteDetalleEsquemaRealizado(id, id_esquema_realizado, id_detalle)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setQuery(initialQuery)
      setTotalCount(0)
      getEsquemaRealizado()
    }
  }

  const updateSeguimiento = async (data) => {
    const text = Boolean(data?.continua_tratamiento)
      ? '¿Actualizar la información del seguimiento? Se enviará a auditar nuevamente'
      : 'Si el paciente no continúa con el tratamiento, se finalizará el mismo. ¿Está de acuerdo?'

    const resp = await Swal.fire({
			title: 'Advertencia',
			text: text,
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: Boolean(data?.continua_tratamiento) ? 'Sí, actualizar' : 'Si, finalizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

    if (resp.isConfirmed) {
      try {
        let updateData = {
          ...data,
          proximo_control: data?.proximo_control ? moment(data.proximo_control).format('YYYY-MM-DD') : '',
        }
        delete updateData.fecha_creacion

        const response = await handleUpdateSeguimiento(id, values?.id, updateData)
        toast.success(response.data.message)
        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const addDetalleEsquemaRealizadoSeguimiento = async (data) => {
    try {
      const response = await handleAddDetalleEsquemaRealizadoSeguimiento(id, values.id, data)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSeguimientos()
      getEsquemaRealizado()
    }
  }

  const autorizarTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Autorizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, autorizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

    if (resp.isConfirmed) {
      try {
        const response = await handleAutorizarTratamiento(id)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const tratamientoPendienteMedico = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Desea que el médico complete información en el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, actualizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

		if (resp.isConfirmed) {
      try {
        const response = await handleTratamientoPendienteMedico(id)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const anularTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Anular el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, anular',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

		if (resp.isConfirmed) {
      try {
        const response = await handleAnularTratamiento(id)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const finalizarTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: 'Finalizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, finalizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

		if (resp.isConfirmed) {
      try {
        const response = await handleFinalizarTratamiento(id)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (values?.id_esquema_realizado) {
      actions.push({
        label: 'Eliminar',
        icon: 'bi bi-trash text-danger',
        action: () => deleteDetalleEsquemaRealizado(currentRow?.id_esquema_realizado, currentRow.id)
      })
    }

    return actions
  }
  /* Menu contextual */

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    if (values) {
      const newValues = {
        ...values,
        fecha_creacion: values?.fecha_creacion ? moment(values.fecha_creacion).format('YYYY-MM-DD') : '',
        fecha_inicio: values?.fecha_inicio ? moment(values.fecha_inicio).format('YYYY-MM-DD') : '',
        fecha_finalizacion: values?.fecha_finalizacion ? moment(values.fecha_finalizacion).format('YYYY-MM-DD') : '',
        continua_tratamiento: Boolean(values?.continua_tratamiento),
        proximo_control: values?.proximo_control ? moment(values.proximo_control).format('YYYY-MM-DD') : '',
        mismo_esquema: Boolean(values?.mismo_esquema),
        observacion: values?.observacion ? values.observacion : '',
      }

      setInitialValues({
        ...newValues,
        esquema_realizado: {
          detalle: [],
          observaciones: '',
        }
      })

      // setEsquemaRealizado({
      //   detalle: [],
      //   observaciones: ''
      // })

      if (esquemaRealizado.detalle.length > 0) {
        setInitialValues({
          ...newValues,
          esquema_realizado: { ...esquemaRealizado },
        })
      }
    }
  }, [values, esquemaRealizado.detalle])

  useEffect(() => {
    getSeguimientos()

    if (values?.id) {
      getEsquemaRealizado()
    }
  }, [values?.id, getSeguimientos, getEsquemaRealizado])

  if (!show) return null

  return (
    <Modal
      show={show}
      onHide={() => {
        setEsquemaRealizado({
          detalle: [],
          observaciones: '',
        })
        onClose()
      }}
      size='xl'
      backdrop='static'
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {
            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
            values?.id &&
            seguimientos.length > 0 && seguimientos[0].id == values.id
              ? `Editar seguimiento #${values?.id}`
              : values?.id && (auth.user.usuario_roles.some(r => r.id == ROLES.AUDITOR) || auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO))
                ? `Detalle de seguimiento #${values?.id}`
                : !values?.id && id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO && auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)
                  ? 'Añadir seguimiento'
                  : null
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleChange }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-2'>
                    <InputBS
                      id='fecha_creacion'
                      name='fecha_creacion'
                      type='date'
                      label='Fecha de seguimiento'
                      disabled
                    />
                  </div>

                  <div className='col-2'>
                    <InputBS
                      id='fecha_inicio'
                      name='fecha_inicio'
                      type='date'
                      label='Fecha inicio'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>

                  <div className='col-2'>
                    <InputBS
                      id='fecha_finalizacion'
                      name='fecha_finalizacion'
                      type='date'
                      label='Fecha finalizacion'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>

                  <div className='col-2'>
                    <InputBS
                      id='ciclos'
                      name='ciclos'
                      type='number'
                      label='Ciclos'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>

                  <div className='col-4'>
                    <SelectField
                      id='respuesta'
                      name='respuesta'
                      label='Respuesta'
                      options={respuestas}
                      placeholder='Seleccione una respuesta'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>
                </div>

                <h2 className='m-0'>Continuación</h2>
                <div className='row my-8'>
                  <div className='col-3'>
                    <SelectField
                      id='continua_tratamiento'
                      name='continua_tratamiento'
                      label='¿Continúa tratamiento?'
                      value={values.continua_tratamiento}
                      options={[
                        { value: false, label: 'No' },
                        { value: true, label: 'Sí' }
                      ]}
                      placeholder='Seleccione una opción'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>

                  {Boolean(values.continua_tratamiento) &&
                    <div className='col-2'>
                      <InputBS
                        id='proximo_control'
                        name='proximo_control'
                        type='date'
                        label='Próximo control'
                        disabled={
                          !(
                            (
                              auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                              values?.id &&
                              seguimientos.length > 0 && seguimientos[0].id == values.id
                            ) ||
                            (
                              auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                              !values?.id
                            )
                          )
                        }
                      />
                    </div>
                  }

                  <div className='col-3'>
                    <SelectField
                      id='mismo_esquema'
                      name='mismo_esquema'
                      label='¿Mismo esquema?'
                      options={[
                        { value: 0, label: 'No' },
                        { value: 1, label: 'Sí' },
                      ]}
                      placeholder='Seleccione una opción'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>
                </div>

                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <h3 className='m-0 align-middle'>Esquema realizado</h3>

                  {values?.id_esquema_realizado
                    ?
                      <Button
                        type='button'
                        variant='primary'
                        onClick={() => setDetalle(true)}
                        disabled={
                          !(
                            (
                              auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                              values?.id &&
                              seguimientos.length > 0 && seguimientos[0].id == values.id
                            ) ||
                            (
                              auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                              !values?.id
                            )
                          )
                        }
                      >
                        <i className='bi bi-save me-2' />
                        Añadir detalle
                      </Button>
                    :
                      <Button
                        type='button'
                        variant='primary'
                        onClick={() => setEsquema(true)}
                        disabled={
                          !(
                            (
                              auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                              values?.id &&
                              seguimientos.length > 0 && seguimientos[0].id == values.id
                            ) ||
                            (
                              auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                              !values?.id
                            )
                          )
                        }
                      >
                        <i className='bi bi-save me-2' />
                        Añadir esquema
                      </Button>
                  }
                </div>

                <EsquemaRealizadoSeguimientoModal
                  show={esquema}
                  onClose={() => {
                    setEsquema(false)
                    setInitialValues(seguimientoInitialValues)
                    setEsquemaRealizado({
                      detalle: [],
                      observaciones: '',
                    })
                  }}
                  onSubmit={(data) => {
                    console.log(data)

                    if (!values?.id) {
                      console.log('Añadir esquema a seguimiento en proceso de creación')
                      // setFieldValue('esquema_realizado.detalle', data.detalle)
                      // setFieldValue('esquema_realizado.observaciones', data.observaciones)
                      // setEsquema(false)
                    } else {
                      console.log(`Añadir esquema a seguimiento #${values.id}`)
                      // addEsqu
                      // setFieldValue('esquema_realizado.detalle', [])
                      // setFieldValue('esquema_realizado.observaciones', '')
                    }
                  }}
                  values={values}
                />

                <EsquemaRealizadoDetalleSeguimientoModal
                  show={detalle}
                  onClose={() => setDetalle(false)}
                  onSubmit={(data) => {
                    if (esquemaRealizado && esquemaRealizado?.detalle && esquemaRealizado.detalle.length > 0 && esquemaRealizado.detalle.some(d => d.id_droga == data.id_droga)) {
                      toast.error("La droga seleccionada ya se encuentra en el detalle del esquema")
                      return
                    }
                    addDetalleEsquemaRealizadoSeguimiento(data)
                    setDetalle(false)
                  }}
                  values={esquemaRealizado}
                />

                <div className='row mb-8'>
                  <TableContainer>
                    <CustomTable
                      columns={[
                        ...COLUMNS,
                        {
                          Header: '',
                          id: 'actions',
                          className:'col-icons',
                          accessor: (row) => (
                            <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
                          ),
                        },
                      ]}
                      // queryMD={query}
                      // setQueryMD={setQuery}
                      data={esquemaRealizado && esquemaRealizado?.detalle && esquemaRealizado.detalle.length > 0 ? esquemaRealizado.detalle : []}
                      paginationOptions={paginationOptions}
                      handleContextMenu={handleContextMenu}
                    />
                  </TableContainer>

                  <ContextMenu
                    showContextMenu={showContextMenu}
                    setShowContextMenu={setShowContextMenu}
                    actions={availableActions()}
                    currentPos={currentPos}
                    ref={contextRef}
                  />
                </div>

                {values?.id_esquema_realizado &&
                  <div className='row my-8'>
                    <div className='col'>
                      <TextareaBS
                        id='esquema_realizado.observaciones'
                        name='esquema_realizado.observaciones'
                        label='Observación del esquema realizado'
                        disabled={
                          !(
                            (
                              auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                              values?.id &&
                              seguimientos.length > 0 && seguimientos[0].id == values.id
                            ) ||
                            (
                              auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                              id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                              !values?.id
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                }

                <div className='row my-8'>
                  <div className='col'>
                    <TextareaBS
                      id='observacion'
                      name='observacion'
                      label='Observación'
                      disabled={
                        !(
                          (
                            auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                            values?.id &&
                            seguimientos.length > 0 && seguimientos[0].id == values.id
                          ) ||
                          (
                            auth.user.usuario_roles.some(r =>r.id == ROLES.ADMIN ||  r.id == ROLES.MEDICO) &&
                            id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                            !values?.id
                          )
                        )
                      }
                    />
                  </div>
                </div>

                {!values?.id && auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) && id_estado_tratamiento == ESTADO_TRATAMIENTO.EN_PROGRESO &&
                  <>
                    <div className='separator my-8'></div>

                    <div className='text-end'>
                      <Button
                        type='button'
                        variant='secondary'
                        className='me-3'
                        onClick={() => {
                          onClose()
                        }}
                      >
                        <i className='bi bi-slash-circle' />
                        Cancelar
                      </Button>

                      <Button type='submit' variant='primary'>
                        <i className='bi bi-save me-2' />
                        Guardar
                      </Button>
                    </div>
                  </>
                }

                {
                  values?.id &&
                  auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.MEDICO) &&
                  id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
                  seguimientos && seguimientos.length > 0 && seguimientos[0]?.id == values?.id &&
                    <>
                      <div className='separator my-8'></div>

                      <div className='text-end'>
                        <Button
                          type='button'
                          variant='secondary'
                          className='me-3'
                          onClick={() => {
                            onClose()
                          }}
                        >
                          <i className='bi bi-slash-circle' />
                          Cancelar
                        </Button>

                        <Button type='button' variant='primary' onClick={() => updateSeguimiento(values)}>
                          <i className='bi bi-save me-2' />
                          Guardar
                        </Button>
                      </div>
                    </>
                }

                {
                  values?.id &&
                  auth.user.usuario_roles.some(r => r.id == ROLES.ADMIN || r.id == ROLES.AUDITOR) &&
                  id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR &&
                  seguimientos && seguimientos.length > 0 && seguimientos[0]?.id == values?.id &&
                    <>
                      <div className='separator my-8'></div>

                      <div className='text-end'>
                        <Button type='button' variant='secondary' className='me-3' onClick={onClose}><i className='bi bi-slash-circle' />Cerrar</Button>
                        <Button type='button' variant='danger' className='me-3' onClick={anularTratamiento}><i className='bi bi-x-circle' />Rechazar</Button>
                        <Button type='button' variant='warning' className='me-3' onClick={tratamientoPendienteMedico}><i className='bi bi-info-circle' />Faltan datos</Button>
                        <Button type='button' variant='success' className='me-3' onClick={autorizarTratamiento}><i className='bi bi-check-all' />Autorizar</Button>
                        <Button type='button' variant='primary' onClick={finalizarTratamiento}><i className='bi bi-check-circle' />Finalizar</Button>
                      </div>
                    </>
                }
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default SeguimientoModal