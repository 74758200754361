import { useState, useCallback } from 'react'

import { ObrasSociales } from 'services'

const useObrasSociales = () => {
  const [loading, setLoading] = useState(false)

  const handleGetObrasSociales = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await ObrasSociales.getObrasSociales(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetObraSocial = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await ObrasSociales.getObraSocial(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddObraSocial = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await ObrasSociales.addObraSocial(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateObraSocial = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await ObrasSociales.updateObraSocial(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetObrasSociales,
    handleGetObraSocial,
    handleAddObraSocial,
    handleUpdateObraSocial,
  }
}

export default useObrasSociales;