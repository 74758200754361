import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useUsuarios } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './columns'

import { ROLES } from 'interfaces'
import routes from 'app/routing/routes'

const initialQuery = {
	pageNumber: 1,
	pageSize: 10,
}

const Usuarios = () => {
	const { auth } = useAuth()

	const [data, setData] = useState([])
	const { COLUMNS } = useGetColumns()

	const [query, setQuery] = useState(initialQuery)
	const [totalCount, setTotalCount] = useState(0)

	const { handleGetUsuarios, handleResetPassword } = useUsuarios()

	const getUsuarios = useCallback(async () => {
		try {
			let params = {}

			if (query && query.pageNumber) {
				params = {
					...params,
					...query,
				}
			}

			const response = await handleGetUsuarios(params)
			setData(response.data.result)
			setTotalCount(response.data.metadata.count)
		} catch (error) {
			toast.error(error.message)
			setData([])
			setTotalCount(0)
		}
	}, [handleGetUsuarios, query])

	/* Menu contextual */
	const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.USERS}/${currentRow?.id}`)
    })

		// if (auth.user.usuario_email != currentRow?.email && currentRow?.id_rol !== ROLES.ADMIN) {
		// 	actions.push({
		// 		label: 'Reestablecer contraseña',
		// 		icon: 'bi bi-arrow-clockwise text-warning',
		// 		action: () => actionResetPassword(currentRow?.id, currentRow?.email)
		// 	})
		// }

    return actions
  }

	const actionResetPassword = async (id, email) => {
		if (auth.user.usuario_email != email) {
			try {
				const res = await Swal.fire({
					title: 'Advertencia',
					text: `¿Quiere restablecer la contraseña del usuario?`,
					icon: 'warning',
					showDenyButton: true,
					denyButtonText: 'No, cancelar',
					confirmButtonText: `Si, reestablecer`,
					customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
					reverseButtons: true,
				})

				if (res.isConfirmed) {
					const response = await handleResetPassword(id)
					toast.success(response.data.message)
				}
			} catch (error) {
				toast.error(error.message)
			} finally {
				getUsuarios()
			}
		}
	}
  /* Fin menu contextual */

	const columns = useMemo(() => {
    const baseColumns = [
			...COLUMNS,
			{
				Header: '',
				id: 'actions',
				className:'col-icons',
				accessor: (row) =>
					<>
						<i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
					</>
			}
		]

		if (data.some(d => d.temp_password)) {
			baseColumns.unshift({
				Header: '',
				id: 'reset-password',
				accessor: (row) => (
					<>
						{row?.temp_password
							?
								<Tooltip text='Reestablecer contraseña'>
									{row?.temp_password && <i className='bi bi-arrow-clockwise text-warning' />}
								</Tooltip>
							: null
						}
					</>
				)
			})
		}

		return baseColumns
	}, [COLUMNS, data])

	const paginationOptions = {
		totalSize: totalCount,
		obQuery: query,
		setObQuery: setQuery,
	}

	useEffect(() => {
		getUsuarios()
	}, [getUsuarios])

	return (
		<>
			<TableContainer
				title={'Usuarios'}
				goTo={routes.USERS_NEW}
				// filter={}
			>
				<CustomTable columns={columns} data={data} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
			</TableContainer>

			<ContextMenu
			showContextMenu={showContextMenu}
			setShowContextMenu={setShowContextMenu}
			actions={availableActions()}
			currentPos={currentPos}
			ref={contextRef}
		/>
		</>
	)
}

export default Usuarios