import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useTratamientos } from 'hooks'

import { ROLES, ESTADO_TRATAMIENTO } from 'interfaces'
import { TratamientoEventosModal } from '../partials'
import routes from 'app/routing/routes'

const MenuActions = ({ values, handleGetTratamiento, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigation = useNavigate()
  const { currentUser } = useAuth();
  const [currentUserRoles, setCurrentUserRoles] = useState([]);

  const [modalEventos, setModalEventos] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { auth } = useAuth()
  const { handleAuditarTratamiento, handleAutorizarTratamiento, handleAnularTratamiento, handleFinalizarTratamiento, handleTratamientoPendienteMedico } = useTratamientos()

  const availableActions = () => {
    let actions = []

    if (values) {
      if (
        auth.user.usuario_roles.some(r => r.id == ROLES.MEDICO || r.id == ROLES.ADMIN) &&
        (values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO)
      ) {
        actions.push({
          label: "Enviar a auditoría",
          icon: "bi bi-box-arrow-in-down-right text-success",
          action: actionAuditar,
        })
      }

      if (auth.user.usuario_roles.some(r => r.id == ROLES.AUDITOR || r.id == ROLES.ADMIN)) {
        if (values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR) {
          actions.push({
            label: 'Cumple criterios de inclusión',
            icon: 'bi bi-check-all text-success',
            action: actionAutorizar,
          })

          actions.push({
            label: 'Faltan datos',
            icon: 'bi bi-info-circle text-warning',
            action: actionPendienteMedico,
          })

          actions.push({
            label: 'No cumple con criterio',
            icon: 'bi bi-x-circle text-danger',
            action: actionAnular
          })

          actions.push({
            label: 'Finalizar',
            icon: 'bi bi-check-circle text-primary',
            action: actionFinalizar
          })
        }
      }
    }

    actions.push({
      label: 'Eventos',
      icon: 'bi bi-archive text-primary',
      action: actionVerEventos
    })

    return actions
  }

  const actionAuditar = async () => {
    if (!(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO)) {
      toast.error('El estado del tratamiento no permite esta acción')
      return
    }
    if (!values.tratamiento_01?.id) {
      toast.error('Debe completar el Tratamiento 01')
      return
    }

    const resp = await Swal.fire({
      title: 'Advertencia',
      text: '¿Quiere enviar el tratamiento para auditar? No podrá hacer más cambios en el mismo.',
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: 'Si, enviar',
      customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      try {
        const response = await handleAuditarTratamiento(values.id)

        toast.success(response.data.message)
        navigation(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionAutorizar = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Autorizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, autorizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

		if (resp.isConfirmed) {
			try {
				const response = await handleAutorizarTratamiento(values.id)
				toast.success(response.data.message)
        navigation(routes.TRATAMIENTOS)
			} catch (error) {
				toast.error(error.message)
			}
		}
	}

	const actionAnular = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Anular el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, anular',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value
      }

			try {
				const response = await handleAnularTratamiento(values.id, data)
				toast.success(response.data.message)
        navigation(routes.TRATAMIENTOS)
			} catch (error) {
				toast.error(error.message)
			}
		}
	}

  const actionFinalizar = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Finalizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, finalizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value
      }

      try {
        const response = await handleFinalizarTratamiento(values.id, data)
        toast.success(response.data.message)

        navigation(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

	const actionPendienteMedico = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Desea que el médico complete información en el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, actualizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value,
      }

			try {
				const response = await handleTratamientoPendienteMedico(values.id, data)
				toast.success(response.data.message)
        navigation(routes.TRATAMIENTOS)
			} catch (error) {
				toast.error(error.message)
			}
		}
	}

  const actionVerEventos = () => {
    setModalEventos(true)
  }

  useEffect(() => {
    if (currentUser) {
      const _roles = currentUser.usuario_roles || []
      setCurrentUserRoles(_roles)
    }
  }, [currentUser])

  return (
    <>
      <TratamientoEventosModal id={values.id} show={modalEventos} onClose={() => setModalEventos(false)} />

      <DropdownButton title="Acciones" disabled={disabled}>
        {availableActions().map((data, index) => (
          <Dropdown.Item key={index} onClick={() => data.action()}>
            <i className={`${data.icon} pe-3`} />
            {data.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  )
}

const Actions = ({ loading, tratamiento, handleGetTratamiento, updating, handleUpdating, submitRef, cancelRef }) => {
  const { auth } = useAuth()
  const navigate = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!updating
        ?
          <div className='d-flex mt-5'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button
              variant='primary'
              className='me-3'
              type='button'
              onClick={handleUpdating}
              disabled={
                !(
                  tratamiento?.tratamiento_evento?.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR ||
                  tratamiento?.tratamiento_evento?.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO
                ) ||
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO))
              }
            >
              <i className='bi bi-pencil' />
              Editar
            </Button>

            {/* {(
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR ||
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO ||
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR
            ) && */}
            <MenuActions values={tratamiento} handleGetTratamiento={handleGetTratamiento} disabled={loading} />
            {/* } */}
          </div>
        :
          <div className='d-flex mt-5'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button className='me-3' variant='primary' type='button' onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>

            {/* {(
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR ||
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO ||
              tratamiento.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR
            ) && */}
            <MenuActions values={tratamiento} handleGetTratamiento={handleGetTratamiento} disabled={loading} />
            {/* } */}
          </div>
      }
    </div>
  )
}

export default Actions