import { useState, useRef, useMemo, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useTratamientos, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, TextareaBS } from 'components'
import ContextMenu from 'components/ContextMenu'

import EsquemaRealizadoDetalleSeguimientoModal from './EsquemaRealizadoDetalleSeguimientoModal'
import EsquemaRealizadoDetalleColumns from './EsquemaRealizadoDetalleColumns'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const EsquemaRealizadoSeguimientoModal = ({ show, onClose, onSubmit, values, handleGetTratamiento, handleGetEsquemaRealizado }) => {
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [esquemaRealizadoDetalleModal, setEsquemaRealizadoDetalleModal] = useState(false)

  const { handleAddDetalleEsquemaRealizadoSeguimiento } = useTratamientos()

  const addDetalleEsquemaRealizado = async (data) => {
    try {
      const params = {
        ...data,
      }
      const response = await handleAddDetalleEsquemaRealizadoSeguimiento(values.id, params)

      toast.success(response.data.message)
      setEsquemaRealizadoDetalleModal(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Eliminar",
      icon: "bi bi-x-circle text-danger",
      action: actionRemove
    })

    return actions
  }

  const actionRemove = async () => {
    if (currentRow) {
    //   const resp = await Swal.fire({
    //     title: 'Advertencia',
    //     text: '¿Quiere eliminar la droga del tratamiento?',
    //     icon: 'warning',
    //     showDenyButton: true,
    //     denyButtonText: 'No, cancelar',
    //     confirmButtonText: 'Si, eliminar',
    //     customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
    //     reverseButtons: true,
    //   })

    //   if (resp.isConfirmed) {
    //     try {
    //       const response = await handleRemoveDrogaRel(id, currentRow?.id_droga)
    //       toast.success(response.data.message)
    //       getDrogasRel()
    //     } catch (error) {
    //       toast.error(error.message)
    //     }
    //   }
    // }
    }
  }
  /* Fin menu contextual */

  const COLUMNS = useMemo(() => [
    ...EsquemaRealizadoDetalleColumns,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      )
    }
  ])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    console.log(values)
  }, [show])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='xl' backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Añadir esquema realizado</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            detalle: [],
            observaciones: '',
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <h3 className='m-0'>Detalle</h3>

                  <Button type='button' variant='primary' onClick={() => setEsquemaRealizadoDetalleModal(true)}>Añadir detalle</Button>
                </div>

                <EsquemaRealizadoDetalleSeguimientoModal
                  show={esquemaRealizadoDetalleModal}
                  onClose={() => setEsquemaRealizadoDetalleModal(false)}
                  onSubmit={data => {
                    if (!values.detalle.some(d => d.id_droga == data.id_droga)) {
                      setFieldValue('detalle', [...values.detalle, data])
                      setEsquemaRealizadoDetalleModal(false)
                    }
                  }}
                  values={values}
                />

                <TableContainer>
                  <CustomTable columns={COLUMNS} data={values.detalle} paginationOptions={paginationOptions} />
                </TableContainer>

                <ContextMenu
                  showContextMenu={showContextMenu}
                  setShowContextMenu={setShowContextMenu}
                  actions={availableActions()}
                  currentPos={currentPos}
                  ref={contextRef}
                />

                <div className='row my-8'>
                  <div className='col'>
                    <TextareaBS
                      id='observaciones'
                      name='observaciones'
                      label='Observaciones'
                      placeholder={`Observaciones del esquema realizado...`}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>
                <div className='text-end'>
                  <Button
                    type='button'
                    variant='secondary'
                    className='me-3'
                    onClick={onClose}
                  >
                    <i className='bi bi-slash-circle' />
                    Cancelar
                  </Button>

                  <Button type='submit' variant='primary' >
                    <i className='bi bi-save me-2' />
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EsquemaRealizadoSeguimientoModal