import { FC } from 'react'
import { Field } from 'formik'

import { CheckboxI } from './types'

const Checkbox: FC<CheckboxI> = ({
  id,
  required = false,
  disabled = false,
  styles = ''
}) => {
  return (
    <Field
      type="checkbox"
      className={`form-checkbox-input ${styles}`}
      id={id}
      name={id}
      required={required}
      disabled={disabled}
    />
  )
}

export default Checkbox