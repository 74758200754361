import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useTratamientos } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { useGetColumns } from './columns'

import { statusLabelColor } from '../constants'
import { ESTADO_TRATAMIENTO, ROLES } from 'interfaces'
import { TratamientoEventosModal } from '../partials'
import routes from 'app/routing/routes'

const initialQuery = {
	pageNumber: 1,
	pageSize: 10,
}

const Tratamientos = () => {
	const { auth } = useAuth()

	const [data, setData] = useState([])
	const { COLUMNS } = useGetColumns()

	const [query, setQuery] = useState(initialQuery)
	const [totalCount, setTotalCount] = useState(0)

	const {
		handleGetTratamientos,
		handleGetReporteTratamientos,
		handleAuditarTratamiento,
		handleAutorizarTratamiento,
		handleAnularTratamiento,
		handleTratamientoPendienteMedico,
		handleFinalizarTratamiento
	} = useTratamientos()

	const [modalEventos, setModalEventos] = useState(false)

	const getTratamientos = useCallback(async () => {
		try {
			let params = {}

			if (query && query.pageNumber) {
				params = {
					...params,
					...query,
				}
			}

			const response = await handleGetTratamientos(params)
			const data = response.data.result
			const dataColored = data.map(d => ({ ...d, label_color: statusLabelColor(d.tratamiento_evento.id_estado_tratamiento) }))

			setData(dataColored)
			setTotalCount(response.data.metadata.count)
		} catch (error) {
			toast.error(error.message)
			setData([])
			setTotalCount(0)
		}
	}, [handleGetTratamientos, query])

	/* Menu contextual */
	const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.TRATAMIENTOS}/${currentRow?.id}`)
    })

		if (currentRow?.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR && currentRow?.tratamiento_01?.id) {
			actions.push({
				label: "Enviar a auditoría",
				icon: "bi bi-box-arrow-in-down-right text-success",
				action: actionAuditar
			})
		}

		if (auth.user.usuario_roles.some(r => r.id == ROLES.AUDITOR || r.id == ROLES.ADMIN) && currentRow?.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR) {
			actions.push({
				label: 'Autorizar tratamiento',
				icon: 'bi bi-check-all text-success',
				action: actionAutorizar,
			})

			actions.push({
				label: 'Faltan datos',
				icon: 'bi bi-info-circle text-warning',
				action: actionPendienteMedico,
			})

			actions.push({
				label: 'Rechazar',
				icon: 'bi bi-x-circle text-danger',
				action: actionAnular
			})

			actions.push({
				label: 'Finalizar',
				icon: 'bi bi-check-circle text-primary',
				action: actionFinalizar
			})
		}

		actions.push({
			label: 'Eventos',
			icon: 'bi bi-archive text-primary',
			action: actionVerEventos,
		})

    return actions
  }

	const actionAuditar = async () => {
    const resp = await Swal.fire({
      title: 'Advertencia',
      text: '¿Quiere enviar el tratamiento para auditar? No podrá hacer más cambios en el mismo.',
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: 'Si, enviar',
      customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      try {
        const response = await handleAuditarTratamiento(currentRow?.id)
        toast.success(response.data.message)
      } catch (error) {
				toast.error(error.message)
      } finally {
				getTratamientos()
			}
    }
  }

	const actionAutorizar = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Autorizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, autorizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

		if (resp.isConfirmed) {
			try {
				const response = await handleAutorizarTratamiento(currentRow?.id)
				toast.success(response.data.message)
			} catch (error) {
				toast.error(error.message)
			} finally {
				getTratamientos()
			}
		}
	}

	const actionAnular = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Anular el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, anular',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
			input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
			const data = {
				observaciones: resp.value,
			}

			try {
				const response = await handleAnularTratamiento(currentRow?.id, data)
				toast.success(response.data.message)
			} catch (error) {
				toast.error(error.message)
			} finally {
				getTratamientos()
			}
		}
	}

	const actionPendienteMedico = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Desea que el médico complete información en el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, actualizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
			input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
			input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
			const data = {
				observaciones: resp.value,
			}

			try {
				const response = await handleTratamientoPendienteMedico(currentRow?.id, data)
				toast.success(response.data.message)
			} catch (error) {
				toast.error(error.message)
			} finally {
				getTratamientos()
			}
		}
	}

	const actionFinalizar = async () => {
		const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Finalizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, finalizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
			input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
			const data = {
				observaciones: resp.value,
			}

			try {
				const response = await handleFinalizarTratamiento(currentRow?.id, data)
				toast.success(response.data.message)
			} catch (error) {
				toast.error(error.message)
			} finally {
				getTratamientos()
			}
		}
	}

	const actionVerEventos = () => {
		setModalEventos(true)
	}
  /* Fin menu contextual */

	const exportData = async () => {
    try {
			let params = {
				...query,
				pageNumber: 1,
				pageSize: undefined,
			}
      const response = await handleGetReporteTratamientos(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          let data = {
            "ID": d.id,
            "Fecha creación": moment(d.fecha_creacion).format('DD/MM/YYYY'),
            "Financiador": d.financiador_nombre,
            "Laboratorio": d.laboratorio_nombre,
            "ID Paciente": d.id_paciente,
            "Paciente": d.paciente_nombre_completo,
            "Medico tratante": d.usuario_creacion,
            "Patología": d.patologia_label,
						"Diagnóstico": d.id_cie10_cabecera,
						"Detalle": d?.id_cie10_detalle,
						"Fecha inicio patología": d?.fecha_inicio_enfermedad ? moment(d.fecha_inicio_enfermedad).format('DD/MM/YYYY') : '',
						"Estadificación inicio": d?.estadificacion_inicial_label,
						"Sistémico inicial - Tipo": d?.sistemico_inicial_tipo,
						"Sistémico inicial - Propósito": d?.sistemico_inicial_proposito,
						"Fecha inicio de tratamiento actual": d?.fecha_actual ? moment(d.fecha_actual).format('DD/MM/YYYY') : '',
						"Estadificación actual": d?.estadificacion_actual_label,
						"Cantidad de seguimientos realizados": d?.cantidad_seguimientos,
						"Fecha último seguimiento": d?.ultimo_seguimiento?.fecha_creacion ? moment(d.ultimo_seguimiento?.fecha_creacion).format('DD/MM/YYYY') : '',
						"Respuesta último seguimiento": d?.ultimo_seguimiento?.respuesta,
						"Continúa": Boolean(d?.ultimo_seguimiento?.continua_tratamiento) ? 'Sí' : 'No',
						"Estado del tratamiento": d?.estado_tratamiento_label,
          }

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Tratamientos")
        XLSX.writeFile(wb, `tratamientos-${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      setData([])
    }
  }

	const columns = useMemo(() => {
    const baseColumns = [
			...COLUMNS,
			{
				Header: '',
				id: 'actions',
				className:'col-icons',
				accessor: (row) =>
					<>
						<i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
					</>
			}
		]

		return baseColumns
	}, [COLUMNS, data])

	const paginationOptions = {
		totalSize: totalCount,
		obQuery: query,
		setObQuery: setQuery,
	}

	useEffect(() => {
		getTratamientos()
	}, [getTratamientos])

	return (
		<>
			<TableContainer
				title={'Tratamientos'}
				goTo={auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) && routes.TRATAMIENTOS_NEW}
				filter={<Filter handleData={setData} query={query} handleQuery={setQuery} exportData={exportData} />}
			>
				<CustomTable columns={columns} data={data} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
			</TableContainer>

			<TratamientoEventosModal id={currentRow?.id} show={modalEventos} onClose={() => setModalEventos(false)} />

			<ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
		</>
	)
}

export default Tratamientos