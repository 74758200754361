const routes = {
  // DASHBOARD
  DASHBOARD: '/dashboard',
  USERS: '/usuarios',
  USERS_NEW: '/usuarios/crear',
  FINANCIADORES: '/financiadores',
  FINANCIADORES_NEW: '/financiadores/crear',
  LABORATORIOS: '/laboratorios',
  LABORATORIOS_NEW: '/laboratorios/crear',
  // RELACIONES COMERCIALES
  PATIENTS: '/pacientes',
  PATIENTS_NEW: '/pacientes/crear',
  // Obras sociales
  OBRAS_SOCIALES: '/obras-sociales',
  OBRAS_SOCIALES_NEW: '/obras-sociales/crear',
  // Tratamientos
  TRATAMIENTOS: '/tratamientos',
  TRATAMIENTOS_NEW: '/tratamientos/crear',
}

export default routes