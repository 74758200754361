import service from 'services/service'

const PATH = {
  estudios: '/estudios',
  nombresEstudio: '/estudios/nombres-estudio',
}

export class Estudios {
  static getEstudios = async (params) => {
    const response = await service.get(`${PATH.estudios}`, {
      params,
    })
    return response
  }

  static getNombresEstudio = async (params) => {
    const response = await service.get(`${PATH.nombresEstudio}`, {
      params,
    })
    return response
  }

  static getEstudio = async (id, params) => {
    const response = await service.get(`${PATH.estudios}/${id}`, {
      params
    })
    return response
  }

  static addEstudio = async (id_tratamiento, id_seguimiento = null, data) => {
    const values = {
      ...data,
      id_seguimiento: id_seguimiento,
    }
    const response = await service.post(`${PATH.estudios}/${id_tratamiento}`, values)

    return response
  }

  static updateEstudio = async (id, data) => {
    const response = await service.patch(`${PATH.estudios}/${id}`, data)
    return response
  }
}