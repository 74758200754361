import { FormButton } from 'components';
import useMaps from 'hooks/useMaps';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const googleAddress = {
    route:null,
    street_number:null,
    locality:null,
    administrative_area_level_1:null,
    administrative_area_level_2:null,
    sublocality_level_1:null,
    postal_code:null,
    country:null,
    formatted_address:null,
    lat:null,
    lng:null,
    location_type:null,
    place_id:null
}


const mapearResultados = (data)=>{
    let result = {};
   
    for(const [key, value] of Object.entries(data.address_components)){
        // me quedo con el primer type que no es "political"
        if(value.types){
            let type = value.types.filter(c=>!c.includes('political'))[0];
            result[type]=value.short_name;
            if(type=='administrative_area_level_1'){
                if(value.short_name=='CABA'){
                    result["zona"]='CABA';
                }
                else if(value.long_name=='Provincia de Buenos Aires'){
                    result["zona"]='BA';
                }
                else{
                    result["zona"]='INTERIOR'
                }
            }
        }
    }
    return result;
}

export function Maps() {

    const [cargaManual, setCargaManual] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    //const [singleSelections, setSingleSelections] = useState([]);
    const { getAutocompleteOptions, getAddressDetail, postAddress, postAddressCustom } = useMaps()
    const [address, setAddress] = useState(null);

    const setSingleSelections = async (e)=>{
        try{
            if(e[0].place_id)
                getAddressDetail(e[0].place_id).then(res=>{
                    // if(res && res.address_components){
                    //     setAddress(mapearResultados(res))
                    // }
                    setAddress(res)
                })
        }
        catch(err){
            console.log(err);
        }
  
    }
  
    const handleSearch = (query) => {
    //   setIsLoading(true);
    
        getAutocompleteOptions(query).then(res=>{

          setOptions(res);

        })

    };
    
    const saveAddress = ()=>{
            //console.log(address)
            if(cargaManual){
                if(address){
                    const {route, street_number, locality} = address;
                    const addr = {route, street_number, locality, locality_custom:address.custom_address_level1}
                    postAddressCustom(addr)
                }

            }
            else{
                if(address && address.place_id)
                    postAddress(address.place_id)
            }

    }

    return (
        <>
            <Card>
                <Card.Header>

                </Card.Header>
                <Card.Body>

                    <div className="row">
                        <div className='col-8'>
                            {!cargaManual && 
                                <>                            
                                    <h5 className="control-label">Domicilio</h5>
                                    <Form.Group>
                                            <AsyncTypeahead
                                                id="buscadorLinkAlfabeta"
                                                className='mr-6'
                                                isLoading={isLoading}
                                                minLength={3}
                                                labelKey={option => `${option.description}`}
                                                onSearch={handleSearch}
                                                options={options}
                                                filterBy={() => true}
                                                prompText='Escribe para buscar un domicilio'
                                                searchText='Buscando...'
                                                placeholder='Escribe para buscar medicamentos'
                                                onChange={setSingleSelections}
                                            />
                                    </Form.Group>
                                </>
                            }
                        </div>
                        <div className='col-4 mt-11'>
                            <Form.Check 
                                type="switch"
                                id="detail"
                                label="Carga Automática"
                                checked={!cargaManual}
                                onChange={(e)=>{ 
                                    // setFieldValue("details", e.target.checked?1:undefined)
                                    setCargaManual(e.target.checked?false:true)
                                    // handleSubmit();
                                }}
                            />
                        </div>

                    </div>

                    {(address || cargaManual) && 
                    <div className="col-xl-12 mt-10">
                        <div className="row">
                            <div className='col-12'>
                                <Form.Group>
                                    <Form.Label>Calle</Form.Label>
                                    <Form.Control type="text" name="route" value={address?.route || ""}  disabled={!cargaManual} 
                                        onChange={(e)=>setAddress({...address, route:e.target.value})}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-4'>
                                <Form.Group>
                                    <Form.Label>Nro</Form.Label>
                                    <Form.Control type="text" name="street_number" value={address?.street_number || ""}  disabled={!cargaManual}  
                                        onChange={(e)=>setAddress({...address, street_number:e.target.value})}/>
                                </Form.Group>
                            </div> 
                            <div className='col-4'>
                                <Form.Group>
                                    <Form.Label>Piso</Form.Label>
                                    <Form.Control type="text" name="piso" value={address?.piso || ""}   maxLength="50"  
                                        onChange={(e)=>setAddress({...address, piso:e.target.value})}/>
                                </Form.Group>
                            </div> 
                            <div className='col-4'>
                                <Form.Group>
                                    <Form.Label>CP</Form.Label>
                                    <Form.Control type="text"  name="postal_code" value={address?.postal_code || ""}  disabled={!cargaManual}  
                                        onChange={(e)=>setAddress({...address, postal_code:e.target.value})}/>
                                </Form.Group>
                            </div> 
                        </div>
                        <div className="row">
                            { true && //address?.zona=='CABA' && 
                            <>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>{address?.custom_address_level1_label || "Localidad"}</Form.Label>
                                        <Form.Control type="text" value={address?.custom_address_level1 || ""} disabled={!cargaManual}
                                        onChange={(e)=>setAddress({...address, custom_address_level1:e.target.value})}/>
                                    </Form.Group>
                                </div>
                                {!cargaManual && <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>{address?.custom_address_level2_label || "Comuna"}</Form.Label>
                                        <Form.Control type="text" value={address?.custom_address_level2 || ""} disabled={true}/>
                                    </Form.Group>
                                </div>}                    
                            </>
                            }
                            {/* {address?.zona=='BA' && 
                            <>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Localidad</Form.Label>
                                        <Form.Control type="text" value={address?.locality || ""} disabled={true}/>
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Partido</Form.Label>
                                        <Form.Control type="text" value={address?.administrative_area_level_2 || ""} disabled={true}/>
                                    </Form.Group>
                                </div>                    
                            </>
                            } */}
                            {/* {address?.zona=='INTERIOR' && 
                            <>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Localidad</Form.Label>
                                        <Form.Control type="text" value={address?.locality || ""} disabled={true}/>
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Label>Departamento</Form.Label>
                                        <Form.Control type="text" value={address?.administrative_area_level_2 || ""} disabled={true}/>
                                    </Form.Group>
                                </div>                    
                            </>
                            } */}
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <Form.Group>
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control type="text" value={address?.administrative_area_level_1 || ""} disabled={!cargaManual}
                                        onChange={(e)=>setAddress({...address, administrative_area_level_1:e.target.value})}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='col-12 text-end mt-10'>
                         <button className="btn btn-primary mt-7" type="submit" onClick={saveAddress}> Guardar </button>
                        </div>

                    </div> }


                </Card.Body>
            </Card>
        </>
    )
}
