import { useState, useCallback } from 'react'

import { Estudios } from 'services'

const useEstudios = () => {
  const [loading, setLoading] = useState(false)

  const handleGetEstudios = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Estudios.getEstudios(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetNombresEstudio = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Estudios.getNombresEstudio(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetEstudio = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Estudios.getEstudio(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetNombreEstudio = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Estudios.getNombreEstudio(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddEstudio = useCallback(async (id_tratamiento, id_seguimiento = null, data) => {
    try {
      setLoading(true)
      const response = await Estudios.addEstudio(id_tratamiento, id_seguimiento, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddNombreEstudio = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Estudios.addNombreEstudio(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateEstudio = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Estudios.updateEstudio(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateNombreEstudio = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Estudios.updateNombreEstudio(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetEstudios,
    handleGetNombresEstudio,
    handleGetEstudio,
    handleGetNombreEstudio,
    handleAddEstudio,
    handleAddNombreEstudio,
    handleUpdateEstudio,
    handleUpdateNombreEstudio,
  }
}

export default useEstudios;