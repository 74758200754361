import service from 'services/service'

const PATH = {
  prestaciones: '/prestaciones',
  prestacionesNomenclador: '/prestaciones/prestaciones-nomenclador',
}

export class Prestaciones {
  static getPrestaciones = async (params) => {
    const response = await service.get(`${PATH.prestaciones}`, {
      params,
    })
    return response
  }

  static getPrestacion = async (id, params) => {
    const response = await service.get(`${PATH.prestaciones}/${id}`, {
      params
    })
    return response
  }

  static addPrestacion = async (data) => {
    const response = await service.post(`${PATH.prestaciones}`, data)
    return response
  }

  static updatePrestacion = async (id, data) => {
    const response = await service.patch(`${PATH.prestaciones}/${id}`, data)
    return response
  }

  static getPrestacionesNomenclador = async (params) => {
    const response = await service.get(PATH.prestacionesNomenclador, {
      params,
    })
    return response
  }
}