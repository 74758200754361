const COLUMNS = [
  {
    Header: 'ID',
    id: 'id_droga',
    accessor: (row) => (
      <>
        {row.id_droga}
      </>
    ),
  },
  {
    Header: 'Droga',
    id: 'droga_nombre',
    accessor: (row) => (
      <>
        {row.droga_nombre}
      </>
    ),
  },
  {
    Header: 'Dosis',
    id: 'dosis',
    accessor: (row) => (
      <>
        {row.dosis}
      </>
    ),
  },
  {
    Header: 'Ciclo días',
    id: 'ciclo_dias',
    accessor: (row) => (
      <>
        {row.ciclo_dias}
      </>
    ),
  },
  {
    Header: 'Cajas por ciclo',
    id: 'cantidad_cajas_por_ciclo',
    accessor: (row) => (
      <>
        {row.cantidad_cajas_por_ciclo}
      </>
    ),
  },
  {
    Header: 'Cantidad de ciclos',
    id: 'cantidad_ciclos',
    accessor: (row) => (
      <>
        {row.cantidad_ciclos}
      </>
    ),
  },
  {
    Header: 'Total cajas',
    id: 'total_cajas',
    accessor: (row) => (
      <>
        {row.total_cajas}
      </>
    ),
  },
]

export default COLUMNS