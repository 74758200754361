import { Link } from 'react-router-dom'
import moment from 'moment'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.PATIENTS}/${row.id}`}>
          {row.id}
        </Link>
      ),
    },
    {
      Header: 'Fecha creación',
      id: 'fecha_creacion',
      accessor: (row) => (
        <>
          {moment(row.fecha_creacion).format('DD/MM/YYYY')}
        </>
      ),
    },
    {
      Header: 'Nombre',
      id: 'nombre_completo',
      accessor: (row) => (
        <>
          {row.nombre} {row.apellido}
        </>
      )
    },
    {
      Header: 'Documento',
      id: 'documento',
      accessor: (row) => (
        <>
          {row.tipo_documento} - {row.documento}
        </>
      ),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => (
        <>
          {row?.email
            ? <>{row.email}</>
            : <div className='text-center'>-</div>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Teléfono fijo</div>,
      id: 'telefono_fijo',
      accessor: (row) => (
        <div className='text-center'>
          {row?.telefono_fijo
            ? row.telefono_fijo
            : '-'
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Celular</div>,
      id: 'telefono_movil',
      accessor: (row) => (
        <div className='text-center'>
          {row?.telefono_movil
            ? row.telefono_movil
            : '-'
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Obra social</div>,
      id: 'obra_social',
      accessor: (row) => (
        <div className='text-center'>
          {row.obra_social_nombre}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Nro. afiliado</div>,
      id: 'numero_afiliado',
      accessor: (row) => (
        <div className='text-center'>
          {row?.numero_afiliado || '-'}
        </div>
      )
    }
  ]

  return {
    COLUMNS,
  }
}