import service from 'services/service'

const PATH = {
  drogas: '/drogas',
}

export class Drogas {
  static getDrogas = async (params) => {
    const response = await service.get(`${PATH.drogas}`, {
      params,
    })
    return response
  }

  static getDroga = async (id, params) => {
    const response = await service.get(`${PATH.drogas}/${id}`, {
      params
    })
    return response
  }

  static addDroga = async (data) => {
    const response = await service.post(`${PATH.drogas}`, data)
    return response
  }

  static updateDroga = async (id, data) => {
    const response = await service.patch(`${PATH.drogas}/${id}`, data)
    return response
  }
}