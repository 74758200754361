import React from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { usePacientes } from 'hooks'
import { Input } from 'components'
import { Button } from 'react-bootstrap'

const Filter = ({ handleData, query, handleQuery }) => {
  const { handleGetPacientes } = usePacientes()

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      text: values?.text? values.text : undefined,
    }

    try {
      const response = await handleGetPacientes('', '', params)

      handleQuery({ ...params, pageNumber: 1 })
      handleData(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Formik
      initialValues={{
        text: '',
      }}
      onSubmit={filterData}
    >
      {({ values, errors }) => (
        <Form className='d-flex flex-column'>
          <div className='row'>

            <div className='col-5'>
              <Input id='text' name='text' />
              <p className='text-muted'>Nombre / Documento / Email / Nro. afiliado</p>
            </div>

            <div className='col text-end'>
              <Button  variant="primary" type='submit'>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter