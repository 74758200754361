import moment from 'moment'

import { Tooltip } from 'components'

export const COLUMNS = [
  {
    Header: <div className='text-center'>Fecha</div>,
    id: 'fecha_creacion',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.fecha_creacion).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'estado_tratamiento',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.estado_tratamiento_label}
      </span>
    ),
  },
  {
    Header: <div className='text-center'>Usuario</div>,
    id: 'id_usuario',
    accessor: (row) => (
      <div className='text-center'>
        {row.usuario.nombre}{row.usuario?.apellido ? ` ${row.usuario.apellido}` : ''}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Rol</div>,
    id: 'rol',
    accessor: (row) => (
      <div className='text-center'>
        {row.usuario.rol}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Observación</div>,
    id: 'observacion',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text={row.observacion}>
          <i className='bi bi-info-circle text-primary' />
        </Tooltip>
      </div>
    ),
  },
]