import service from 'services/service'

const PATH = {
  obrasSociales: '/obras-sociales',
}

export class ObrasSociales {
  static getObrasSociales = async (params) => {
    const response = await service.get(`${PATH.obrasSociales}`, {
      params,
    })
    return response
  }

  static getObraSocial = async (id, params) => {
    const response = await service.get(`${PATH.obrasSociales}/${id}`, {
      params
    })
    return response
  }

  static addObraSocial = async (data) => {
    const response = await service.post(`${PATH.obrasSociales}`, data)
    return response
  }

  static updateObraSocial = async (id, data) => {
    const response = await service.patch(`${PATH.obrasSociales}/${id}`, data)
    return response
  }
}