import service from 'services/service'

const PATH = {
  tiposTratamiento: '/tipos-tratamiento',
}

export class TiposTratamiento {
  static getTiposTratamiento = async (params) => {
    const response = await service.get(`${PATH.tiposTratamiento}`, {
      params,
    })
    return response
  }

  static getTipoTratamiento = async (id, params) => {
    const response = await service.get(`${PATH.tiposTratamiento}/${id}`, {
      params
    })
    return response
  }

  static addTipoTratamiento = async (data) => {
    const response = await service.post(`${PATH.tiposTratamiento}`, data)
    return response
  }

  static updateTipoTratamiento = async (id, data) => {
    const response = await service.patch(`${PATH.tiposTratamiento}/${id}`, data)
    return response
  }
}