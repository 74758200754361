import * as Yup from 'yup'

const financiadorInitialValues = {
	id: '',
	nombre: '',
	apellido: '',
	email: '',
	telefono_movil: '',
	activo: 1,
	id_rol: '',
	rol_nombre: '',
}

const financiadorSchema = Yup.object().shape({
  nombre: Yup.string().required('* Ingrese el nombre del financiador'),
  apellido: Yup.string(),
  email: Yup.string().email('* Ingrese un email válido').required('* Ingrese un email para el financiador'),
	password: Yup.string()
		.required('* Ingrese una contraseña')
		.min(8, '* La contraseña debe tener al menos 8 caracteres')
		.matches(/\d/, '* La contraseña debe contener al menos un número')
		.matches(/[A-Z]/, '* La contraseña debe contener al menos una mayúscula'),
	password_confirm: Yup.string().required('* Confirme la contraseña'),
  telefono_movil: Yup.string(),
})

const financiadorDetalleSchema = Yup.object().shape({
	nombre: Yup.string().required('* Ingrese el nombre del financiador'),
	apellido: Yup.string(),
	email: Yup.string().email('* Ingrese un email válido').required('* Ingrese un email para el financiador'),
	telefono_movil: Yup.string(),
	activo: Yup.number()
})

const financiadorAdapter = (financiador) => {
	let values = {
		...financiador,
		apellido: financiador?.apellido || '',
		telefono_movil: financiador?.telefono_movil || '',
	}

	return values
}

const updateFinanciadorAdapter = (financiador) => {
	let values = {
		...financiador,
		telefono_movil: financiador?.telefono_movil || '',
	}

	return values
}

export {
	financiadorInitialValues,
  financiadorSchema,
	financiadorDetalleSchema,
	financiadorAdapter,
	updateFinanciadorAdapter,
}