import React from 'react'
import { useParams } from 'react-router-dom'

const LaboratorioDetalle = () => {
  const { id } = useParams()
  return (
    <div>Laboratorio #{id}</div>
  )
}

export default LaboratorioDetalle