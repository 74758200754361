import service from "services/service"

const PATH = {
  Notifications: "/notification",
};

export class Notifications {
  static getNotifications = async () => {
    const response = await service.get(PATH.Notifications)
    return response;
  };
  static getNotificationsAndClean = async () => {
    const response = await service.get(`${PATH.Notifications}?read=1`)
    return response
  };
  static getUnreadedNotifications = async (id) => {
    const response = await service.get(`${PATH.Notifications}/unreaded`)
    return response
  };
}