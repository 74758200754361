import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { usePacientes, useObrasSociales } from 'hooks'

import { DetailContainer } from 'containers'
import { AddressModal } from 'containers/Modals'
import { InputBS, SelectField, FormTable } from 'components'

import { pacienteInitialValues, pacienteSchema, tiposDocumento } from '../constants'
import routes from 'app/routing/routes'

const PacienteCreacion = () => {
  const navigate = useNavigate()
  const submitRef = useRef(null)

  const { loading, handleAddPaciente } = usePacientes()
  const { loading: loadingOS, handleGetObrasSociales } = useObrasSociales()
  const [obrasSociales, setObrasSociales] = useState([])
  const [modal, setModal] = useState(false)

  const addPaciente = async (data) => {
    if (data.id_obra_social == '') {
      toast.error('Debe seleccionar una obra social para el paciente')
      return
    }
    if (data.address?.route == '') {
      toast.error('Debe ingresar una dirección para el paciente')
      return
    }

    try {
      const response = await handleAddPaciente(data)

      toast.success(response.data.message)
      navigate(routes.PATIENTS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getObrasSociales = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
      }
      const response = await handleGetObrasSociales(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.nombre }))

      setObrasSociales(data)
    } catch (error) {
      toast.error(error.message)
      setObrasSociales([])
    }
  }, [handleGetObrasSociales])

  useEffect(() => {
    getObrasSociales()
  }, [getObrasSociales])

  return (
    <DetailContainer
      title={'Crear usuario'}
      buttons={
        <div className='d-flex align-items-center justify-content-end'>
          <Button type='button' variant='secondary' onClick={() => navigate(-1)} className='me-3' disabled={loading || loadingOS}><i className="ki-duotone ki-left" />Volver</Button>
          <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading || loadingOS}><i className='bi bi-save me-2' />Guardar</Button>
        </div>
      }
    >
      <Formik
        initialValues={pacienteInitialValues}
        validationSchema={pacienteSchema}
        onSubmit={addPaciente}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='nombre' name='nombre' type='text' label='Nombre' />
                </div>

                <div className='col'>
                  <InputBS id='apellido' name='apellido' type='text' label='Apellido' />
                </div>

                <div className='col'>
                  <SelectField
                    id='tipo_documento'
                    name='tipo_documento'
                    label='Tipo documento'
                    options={tiposDocumento}
                  />
                </div>

                <div className='col'>
                  <InputBS id='documento' name='documento' type='text' label='Documento' />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='email' name='email' type='email' label='Email' />
                </div>

                <div className='col'>
                  <InputBS id='telefono_fijo' name='telefono_fijo' type='text' label='Teléfono' />
                </div>

                <div className='col'>
                  <InputBS id='telefono_movil' name='telefono_movil' type='text' label='Celular' />
                </div>
              </div>

              <AddressModal
                show={modal}
                onClose={() => setModal(false)}
                values={values}
                onSave={(data) => {
                  setFieldValue('address', data.address)
                  setModal(false)
                }}
              />

              <div className='separator my-8' />
              <div className='mb-8 d-flex flex-row justify-content-between align-items-center'>
                <h2 className='m-0'>Dirección</h2>
                <Button className='BSButton' variant='outline-primary' onClick={() => setModal(true)}>Añadir dirección</Button>
              </div>

              {values?.address?.route &&
                <FormTable
                  columns={[
                    { header: 'Calle' },
                    { header: 'CP' },
                    { header: values.address.custom_address_level1_label },
                    { header: values.address.custom_address_level2_label },
                    { header: 'País' },
                    { header: '', styles: 'text-center' },
                  ]}
                  responsive={false}
                >
                  <tr>
                    <td className='align-middle'>{values.address.route} {values.address.street_number}</td>
                    <td className='align-middle'>{values.address.postal_code}{values.address.postal_code_suffix}</td>
                    <td className='align-middle'>{values.address.custom_address_level1}</td>
                    <td className='align-middle'>{values.address.custom_address_level2}</td>
                    <td className='align-middle'>{values.address.country}</td>
                    <td className='align-middle text-center'>
                      <button type='button' className='btn btn-icon' onClick={() => setModal(true)}>
                        <i className='fas fa-pencil text-primary'></i>
                      </button>
                    </td>
                  </tr>
                </FormTable>
              }
              <div className='separator my-8' />

              <div className='row my-8'>
                <div className='col'>
                  <SelectField
                    id='id_obra_social'
                    name='id_obra_social'
                    label='Obra social'
                    options={obrasSociales}
                  />
                </div>

                <div className='col'>
                  <InputBS id='numero_afiliado' name='numero_afiliado' type='text' label='Nro. afiliado' />
                </div>
              </div>

              <div className='separator my-8' />

              <div className='text-end'>
                <Button ref={submitRef} type='submit' variant='primary' disabled={loading || loadingOS}>Guardar</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default PacienteCreacion