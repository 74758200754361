import { useState, useCallback } from 'react'

import { Roles } from 'services'

const useRoles = () => {
  const [loading, setLoading] = useState(false)

  const handleGetRoles = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Roles.get(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetRol = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Roles.getRol(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddRol = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Roles.add(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateRol = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Roles.update(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetRoles,
    handleGetRol,
    handleAddRol,
    handleUpdateRol,
  }
}

export default useRoles;