import { useState, useCallback } from 'react'

import { Laboratorios } from 'services'

const useLaboratorios = () => {
  const [loading, setLoading] = useState(false)

  const handleGetLaboratorios = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Laboratorios.get(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetLaboratorio = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Laboratorios.getLaboratorio(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddLaboratorio = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Laboratorios.add(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateLaboratorio = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Laboratorios.update(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetLaboratorios,
    handleGetLaboratorio,
    handleAddLaboratorio,
    handleUpdateLaboratorio,
  }
}

export default useLaboratorios;