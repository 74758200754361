import { Link } from 'react-router-dom'
import moment from 'moment'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.USERS}/${row.id}`}>
          {row.id}
        </Link>
      ),
    },
    {
      Header: <div className='text-center'>Últ. inicio sesión</div>,
      id: 'fecha_ultimo_login',
      accessor: (row) => (
        <div className='text-center'>
          {row?.fecha_ultimo_login ? moment(row.fecha_ultimo_login).format('DD/MM/YYYY HH:mm:ss') : '-'}
        </div>
      ),
    },
    {
      Header: 'Nombre',
      id: 'nombre_completo',
      accessor: (row) => (
        <>
          {row.nombre}{row?.apellido ? ` ${row.apellido}` : ''}
        </>
      )
    },
    {
      Header: <div className='text-center'>Rol</div>,
      id: 'rol_nombre',
      accessor: (row) => (
        <div className='text-center'>
          {row.rol_nombre}
        </div>
      ),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => (
        <>
          {row?.email
            ? <>{row.email}</>
            : <div className='text-center'>-</div>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Celular</div>,
      id: 'telefono_movil',
      accessor: (row) => (
        <div className='text-center'>
          {row?.telefono_movil || '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Activo</div>,
      id: 'activo',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.activo)
            ? <span className='badge badge-success'>Sí</span>
            : <span className='badge badge-danger'>No</span>
          }
        </div>
      )
    }
  ]

  return {
    COLUMNS,
  }
}