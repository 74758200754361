import React from 'react'
import { useParams } from 'react-router-dom'

const FinanciadorDetalle = () => {
  const { id } = useParams()

  return (
    <div>Financiador #{id}</div>
  )
}

export default FinanciadorDetalle