import { useState, useCallback, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button, FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { useTratamientos, usePrestaciones, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { SelectField, TextareaBS, Label, Checkbox, InputBS, CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import EsquemaRealizadoModal from './EsquemaRealizadoModal'
import EsquemaRealizadoDetalleModal from './EsquemaRealizadoDetalleModal'
import COLUMNS from './EsquemaRealizadoColumns'

import { ROLES, ESTADO_TRATAMIENTO } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const Tratamiento01Tab = ({ updating, handleGetTratamiento }) => {
  const { id } = useParams()
  const { auth } = useAuth()
  const { values, setFieldValue } = useFormikContext()

  const [queryInicial, setQueryInicial] = useState(initialQuery)
  const [totalCountInicial, setTotalCountInicial] = useState(0)
  const [queryActual, setQueryActual] = useState(initialQuery)
  const [totalCountActual, setTotalCountActual] = useState(0)

  const [esquemaRealizadoInicialModal, setEsquemaRealizadoInicialModal] = useState(false)
  const [detalleEsquemaRealizadoInicialModal, setDetalleEsquemaRealizadoInicialModal] = useState(false)

  const [esquemaRealizadoActualModal, setEsquemaRealizadoActualModal] = useState(false)
  const [detalleEsquemaRealizadoActualModal, setDetalleEsquemaRealizadoActualModal] = useState(false)

  const { handleGetEstadificaciones, handleGetEsquemaRealizadoInicial, handleGetEsquemaRealizadoActual, handleAddEsquemaRealizadoDetalle, handleDeleteDetalleEsquemaRealizado } = useTratamientos()
  const [estadificaciones, setEstadificaciones] = useState([])
  const [esquemaRealizadoInicial, setEsquemaRealizadoInicial] = useState(null)
  const [esquemaRealizadoActual, setEsquemaRealizadoActual] = useState(null)

  const { loading: pLoading, handleGetPrestaciones, handleGetPrestacionesNomenclador } = usePrestaciones()
  const [prestaciones, setPrestaciones] = useState([])
  const [prestacionesNomenclador, setPrestacionesNomenclador] = useState([])

  const getEstadificaciones = useCallback(async () => {
    try {
      let params = {
        activo: 1,
        sortOrder: 'ASC',
      }
      const response = await handleGetEstadificaciones(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setEstadificaciones(data)
    } catch (error) {
      toast.error(error.message)
      setEstadificaciones([])
    }
  }, [handleGetEstadificaciones])

  const getPrestaciones = useCallback(async () => {
    try {
      let params = {
        activo: 1,
        sortOrder: 'ASC',
      }
      const response = await handleGetPrestaciones(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.nombre }))

      setPrestaciones(data)
    } catch (error) {
      toast.error(error.message)
      setPrestaciones([])
    }
  }, [handleGetPrestaciones])

  const getPrestacionesNomenclador = async (text) => {
    try {
      let params = {
        text: text,
      }
      const response = await handleGetPrestacionesNomenclador(params)
      const data = response.data.result

      setPrestacionesNomenclador(data)
    } catch (error) {
      toast.error(error.message)
      setPrestacionesNomenclador([])
    }
  }

  const getEsquemaRealizadoInicial = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC'
      }

      if (queryInicial && queryInicial.pageNumber) {
        params = {
          ...params,
          ...queryInicial,
        }
      }

      const response = await handleGetEsquemaRealizadoInicial(values.id, params)
      const data = response.data.result

      setEsquemaRealizadoInicial(data)
      setFieldValue('tratamiento_01.esquema_realizado_inicial_observaciones', data.observaciones)
      setTotalCountInicial(response.data.metadata.count)
    } catch (error) {
      setEsquemaRealizadoInicial(null)
      setFieldValue('tratamiento_01.esquema_realizado_inicial_observaciones', '')
      setTotalCountInicial(0)
      toast.error(error.message)
    }
  }, [handleGetEsquemaRealizadoInicial, values.id, queryInicial])

  const getEsquemaRealizadoActual = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC'
      }

      if (queryActual && queryActual.pageNumber) {
        params = {
          ...params,
          ...queryActual,
        }
      }

      const response = await handleGetEsquemaRealizadoActual(values.id, params)
      const data = response.data.result

      setEsquemaRealizadoActual(data)
      setFieldValue('tratamiento_01.esquema_realizado_actual_observaciones', data.observaciones)
      setTotalCountActual(response.data.metadata.count)
    } catch (error) {
      setEsquemaRealizadoActual(null)
      setFieldValue('tratamiento_01.esquema_realizado_actual_observaciones', '')
      setTotalCountActual(0)
      toast.error(error.message)
    }
  }, [handleGetEsquemaRealizadoActual, values.id, queryActual])

  const addDetalleEsquemaRealizado = async (actual = false, id_esquema_realizado, detalle) => {
    try {
      const response = await handleAddEsquemaRealizadoDetalle(id, id_esquema_realizado, detalle)
      toast.success(`"${detalle.droga_nombre}" añadida correctamente al esquema ${actual ? 'realizado inicial' : 'indicado'}`);
    } catch (error) {
      toast.error(error.message)
    } finally {
      getEsquemaRealizadoInicial()
      getEsquemaRealizadoActual()
    }
  }

  const deleteDetalleEsquemaRealizado = async (actual = false, id_esquema_realizado, id_detalle, detalle_droga_nombre) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere eliminar "${detalle_droga_nombre}" del esquema ${actual ? 'realizado inicial' : 'indicado'}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, eliminar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteDetalleEsquemaRealizado(id, id_esquema_realizado, id_detalle)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetTratamiento()

      setQueryInicial(initialQuery)
      setTotalCountInicial(0)
      getEsquemaRealizadoInicial()

      setQueryActual(initialQuery)
      setTotalCountActual(0)
      getEsquemaRealizadoActual()
    }
  }

  /* Menu contextual inicial */
  const [showContextMenuInicial, setShowContextMenuInicial] = useState(false)
  const [currentPosInicial, setCurrentPosInicial] = useState({ x: 0, y: 0 })
  const [currentRowInicial, setCurrentRowInicial] = useState(null)
  const threeDotsRefInicial = useRef(null);
  const contextRefInicial = useRef()

  const handleContextMenuInicial = (e, row) => {
    setCurrentRowInicial(row)
    if (e) {
      e.preventDefault()
      setCurrentPosInicial({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenuInicial) {
        setShowContextMenuInicial(true)
      }
    }
  }

  useClickOutside(contextRefInicial, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPosInicial.target === 'threeDotsInicial') {
      setCurrentPosInicial({ ...currentPosActual, target: '' })
      return
    }

    if (showContextMenuInicial) {
      setShowContextMenuInicial(false)
    }
  })

  const availableActionsInicial = () => {
    let actions = []

    if (values?.tratamiento_01 && values?.tratamiento_01?.id_esquema_realizado_inicial) {
      actions.push({
        label: 'Eliminar',
        icon: 'bi bi-trash text-danger',
        action: () => deleteDetalleEsquemaRealizado(true, currentRowInicial?.id_esquema_realizado, currentRowInicial.id, currentRowInicial.droga_nombre)
      })
    }

    return actions
  }
  /* Menu contextual inicial */

  /* Menu contextual actual */
  const [showContextMenuActual, setShowContextMenuActual] = useState(false)
  const [currentPosActual, setCurrentPosActual] = useState({ x: 0, y: 0 })
  const [currentRowActual, setCurrentRowActual] = useState(null)
  const threeDotsRefActual = useRef(null);
  const contextRefActual = useRef()

  const handleContextMenuActual = (e, row) => {
    setCurrentRowActual(row)
    if (e) {
      e.preventDefault()
      setCurrentPosActual({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenuActual) {
        setShowContextMenuActual(true)
      }
    }
  }

  useClickOutside(contextRefActual, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPosActual.target === 'threeDotsActual') {
      setCurrentPosActual({ ...currentPosActual, target: '' })
      return
    }

    if (showContextMenuActual) {
      setShowContextMenuActual(false)
    }
  })

  const availableActionsActual = () => {
    let actions = []

    if (values?.tratamiento_01 && values?.tratamiento_01?.id_esquema_realizado_actual) {
      actions.push({
        label: 'Eliminar',
        icon: 'bi bi-trash text-danger',
        action: () => deleteDetalleEsquemaRealizado(false, currentRowActual?.id_esquema_realizado, currentRowActual.id, currentRowActual.droga_nombre)
      })
    }

    return actions
  }
  /* Menu contextual actual */

  const paginationOptionsInicial = {
    totalSize: totalCountInicial,
    obQuery: queryInicial,
    setObQuery: setQueryInicial,
  }

  const paginationOptionsActual = {
    totalSize: totalCountActual,
    obQuery: queryActual,
    setObQuery: setQueryActual,
  }

  useEffect(() => {
    getEstadificaciones()
    getPrestaciones()
  }, [getEstadificaciones, getPrestaciones])

  useEffect(() => {
    getEsquemaRealizadoInicial()
  }, [getEsquemaRealizadoInicial])

  useEffect(() => {
    getEsquemaRealizadoActual()
  }, [getEsquemaRealizadoActual])

  return (
    <>
      <h2 className='m-0'>Información inicial </h2>

      <div className='row my-8'>
        <div className='col-2'>
          <InputBS
            id='tratamiento_01.fecha_inicio'
            name='tratamiento_01.fecha_inicio'
            type='date'
            label='Fecha inicio'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>

        <div className='col-4'>
          <SelectField
            id='tratamiento_01.id_estadificacion_inicial'
            name='tratamiento_01.id_estadificacion_inicial'
            label='Estadificación inicial'
            placeholder='Seleccionar estadificación inicial'
            options={estadificaciones}
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col'>
          <TextareaBS
            id='tratamiento_01.tratamiento_inicial'
            name='tratamiento_01.tratamiento_inicial'
            label='Tratamiento inicial'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='tratamiento_01.tuvo_cirugia' label={'Cirugía'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox
                id='tratamiento_01.tuvo_cirugia'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
              <Label htmlFor='tratamiento_01.tuvo_cirugia' label={'¿Tuvo cirugía?'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.tratamiento_01?.tuvo_cirugia) &&
          <>
            <div className='col-2'>
              <InputBS
                id='tratamiento_01.cirugia_fecha'
                name='tratamiento_01.cirugia_fecha'
                type='date'
                label='Fecha de cirugía'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
            </div>

            <div className='col-7'>
              <FormLabel style={{ fontSize: '1.05rem', fontWeight: '500', color: 'var(--bs-gray-800)' }}>Prestación</FormLabel>
              <AsyncTypeahead
                id='prestacionesNomencladorSearch'
                isLoading={pLoading}
                minLength={3}
                labelKey={(o) => `${o.cod} - ${o.descripcion}`}
                onSearch={getPrestacionesNomenclador}
                options={prestacionesNomenclador}
                filterBy={() => true}
                prompText='Escriba el código y/o descripción de la prestación'
                searchText='Buscando...'
                placeholder='Escriba el código y/o descripción de la prestación'
                defaultInputValue={values?.tratamiento_01?.cod_prestacion_nomenclador ? `${values.tratamiento_01.cod_prestacion_nomenclador} - ${values.tratamiento_01.prestacion_nomenclador_descripcion}` : ''}
                onChange={(e) => {
                  if (e && e.length > 0) {
                    setFieldValue('tratamiento_01.cod_prestacion_nomenclador', e[0].cod)
                    setFieldValue('tratamiento_01.cirugia_descripcion', e[0].descripcion)
                  }
                }}
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
            </div>

            <div className='col-12'>
              <TextareaBS
                id='tratamiento_01.cirugia_descripcion'
                name='tratamiento_01.cirugia_descripcion'
                label='Descripción'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
            </div>
          </>
        }
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='tratamiento_01.tuvo_rayos' label={'Rayos'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox
                id='tratamiento_01.tuvo_rayos'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
              <Label htmlFor='tratamiento_01.tuvo_rayos' label={'¿Tuvo rayos?'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.tratamiento_01.tuvo_rayos) &&
          <>
            <div className='col-2'>
              <InputBS
                id='tratamiento_01.rayos_dosis'
                name='tratamiento_01.rayos_dosis'
                type='number'
                label='Rayos dosis'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
            </div>

            <div className='col'>
              <InputBS
                id='tratamiento_01.rayos_zona'
                name='tratamiento_01.rayos_zona'
                label='Rayos zona / Descripción'
                disabled={
                  !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                  !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                  !updating
                }
              />
            </div>
          </>
        }
      </div>

      {/* INICIO - SISTÉMICO INICIAL - INICIO */}
      <div className='separator my-8'></div>
      <h3 className='m-0'>Sistémico inicial</h3>

      <div className='row my-8'>
        <div className='col-6'>
          <SelectField
            id='tratamiento_01.sistemico_inicial_tipo'
            name='tratamiento_01.sistemico_inicial_tipo'
            label='Tipo'
            options={[
              { value: 1, label: 'Quimioterapia' },
              { value: 2, label: 'Hormonoterapia' },
              { value: 3, label: 'Inmunoterapia' },
              { value: 4, label: 'Terapias dirigidas' },
            ]}
            placeholder='Seleccione un tipo'
            isMulti
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>

        <div className='col-6'>
          <SelectField
            id='tratamiento_01.sistemico_inicial_proposito'
            name='tratamiento_01.sistemico_inicial_proposito'
            label='Propósito'
            options={[
              { value: 'CURATIVO', label: 'Curativo' },
              { value: 'ADYUVANTE', label: 'Adyuvante' },
              { value: 'NEOADYUVANTE', label: 'Neoadyuvante' },
              { value: 'PALIATIVO', label: 'Paliativo' },
              { value: 'DE_MANTENIMIENTO', label: 'De mantenimiento' },
              { value: 'SALVATAJE_RESCATE', label: 'Salvataje o Rescate' },
            ]}
            placeholder='Seleccione un propósito'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col'>
          <TextareaBS
            id='tratamiento_01.sistemico_inicial'
            name='tratamiento_01.sistemico_inicial'
            label='Observaciones'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>
      <div className='separator my-8'></div>
      {/* FIN - SISTÉMICO INICIAL - FIN */}

      {/* INICIO - ESQUEMA REALIZADO INICIAL - INICIO */}
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h3 className='m-0 align-middle'>Esquema realizado inicial</h3>

        {values?.tratamiento_01 && values.tratamiento_01?.id_esquema_realizado_inicial
          ?
            <Button
              type='button'
              variant='primary'
              onClick={() => setDetalleEsquemaRealizadoInicialModal(true)}
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR
              }
            >
              <i className='bi bi-save me-2' />
              Añadir detalle
            </Button>
          :
            <Button
              type='button'
              variant='primary'
              onClick={() => setEsquemaRealizadoInicialModal(true)}
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR
              }
            >
              <i className='bi bi-save me-2' />
              Añadir esquema inicial
            </Button>
        }
      </div>

      <EsquemaRealizadoModal
        inicial
        show={esquemaRealizadoInicialModal}
        onClose={() => setEsquemaRealizadoInicialModal(false)}
        onSubmit={v => console.log(v)}
        handleGetTratamiento={handleGetTratamiento}
        handleGetEsquemaRealizado={() => {
          handleGetTratamiento()
          getEsquemaRealizadoInicial()
          getEsquemaRealizadoActual()
        }}
        refresh={() => {
          handleGetTratamiento()
          getEsquemaRealizadoInicial()
          getEsquemaRealizadoActual()
        }}
      />

      <EsquemaRealizadoDetalleModal
        inicial
        show={detalleEsquemaRealizadoInicialModal}
        onClose={() => setDetalleEsquemaRealizadoInicialModal(false)}
        onSubmit={(data) => {
          if (esquemaRealizadoInicial.detalle.length > 0 && esquemaRealizadoInicial.detalle.some(d => d.id_droga == data.id_droga)) {
            toast.error(`La droga ya se encuentra asignada al esquema realizado inicial #${values.tratamiento_01.id_esquema_realizado_inicial}`)
            return
          }

          addDetalleEsquemaRealizado(true, values.tratamiento_01.id_esquema_realizado_inicial, data)
          setDetalleEsquemaRealizadoInicialModal(false)
        }}
      />

      <div className='row mb-8'>
        <TableContainer>
          <CustomTable
            columns={[
              ...COLUMNS,
              {
                Header: '',
                id: 'actions',
                className:'col-icons',
                accessor: (row) => (
                  <i ref={threeDotsRefInicial} id='threeDotsInicial' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenuInicial(event, row)} />
                ),
              },
            ]}
            data={esquemaRealizadoInicial && esquemaRealizadoInicial?.detalle && esquemaRealizadoInicial.detalle.length > 0 ? esquemaRealizadoInicial.detalle : []}
            paginationOptions={paginationOptionsInicial}
            handleContextMenu={handleContextMenuInicial}
          />
        </TableContainer>
      </div>

      <ContextMenu
        showContextMenu={showContextMenuInicial}
        setShowContextMenu={setShowContextMenuInicial}
        actions={availableActionsInicial()}
        currentPos={currentPosInicial}
        ref={contextRefInicial}
      />

      {values.tratamiento_01?.id_esquema_realizado_inicial &&
        <div className='row my-8'>
          <div className='col'>
            <TextareaBS
              id='tratamiento_01.esquema_realizado_inicial_observaciones'
              name='tratamiento_01.esquema_realizado_inicial_observaciones'
              label='Observaciones de esquema realizado inicial'
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                !updating
              }
            />
          </div>
        </div>
      }
      <div className='separator my-8'></div>
      {/* FIN - ESQUEMA REALIZADO INICIAL - FIN */}

      <div className='row my-8'>
        <div className='col'>
          <TextareaBS
            id='tratamiento_01.respuesta_inicial'
            name='tratamiento_01.respuesta_inicial'
            label='Respuesta inicial'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      <div className='separator my-8' style={{ borderBottom: '2px solid #ccc' }}></div>
      <h2 className='m-0'>Información actual a la fecha {moment(values.tratamiento_01.fecha_actual).format('DD/MM/YYYY')}</h2>

      <div className='row my-8'>
        <div className='col-2'>
          <InputBS
            id='tratamiento_01.fecha_actual'
            name='tratamiento_01.fecha_actual'
            type='date'
            label='Fecha actual'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>

        <div className='col-4'>
          <SelectField
            id='tratamiento_01.id_estadificacion_actual'
            name='tratamiento_01.id_estadificacion_actual'
            label='Estadificación actual'
            placeholder='Seleccionar estadificación actual'
            options={estadificaciones}
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      {/* INICIO - ESQUEMA REALIZADO ACTUAL - INICIO */}
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h3 className='m-0 align-middle'>Esquema indicado</h3>

        {values?.tratamiento_01 && values.tratamiento_01?.id_esquema_realizado_actual
          ?
            <Button
              type='button'
              variant='primary'
              onClick={() => setDetalleEsquemaRealizadoActualModal(true)}
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR
              }
            >
              <i className='bi bi-save me-2' />
              Añadir detalle
            </Button>
          :
            <Button
              type='button'
              variant='primary'
              onClick={() => setEsquemaRealizadoActualModal(true)}
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR
              }
            >
              <i className='bi bi-save me-2' />
              Añadir esquema indicado
            </Button>
        }
      </div>

      <EsquemaRealizadoModal
        inicial={false}
        show={esquemaRealizadoActualModal}
        onClose={() => setEsquemaRealizadoActualModal(false)}
        onSubmit={v => console.log(v)}
        handleGetTratamiento={handleGetTratamiento}
        handleGetEsquemaRealizado={() => {
          getEsquemaRealizadoInicial()
          getEsquemaRealizadoActual()
        }}
        refresh={() => {
          handleGetTratamiento()
          getEsquemaRealizadoInicial()
          getEsquemaRealizadoActual()
        }}
      />

      <EsquemaRealizadoDetalleModal
        inicial={false}
        show={detalleEsquemaRealizadoActualModal}
        onClose={() => setDetalleEsquemaRealizadoActualModal(false)}
        onSubmit={(data) => {
          if (esquemaRealizadoActual.detalle.length > 0 && esquemaRealizadoActual.detalle.some(d => d.id_droga == data.id_droga)) {
            toast.error(`La droga ya se encuentra asignada al esquema indicado #${values.tratamiento_01.id_esquema_realizado_actual}`)
            return
          }

          addDetalleEsquemaRealizado(false, values.tratamiento_01.id_esquema_realizado_actual, data)
          setDetalleEsquemaRealizadoActualModal(false)
        }}
      />

      <div className='row mb-8'>
        <TableContainer>
          <CustomTable
            columns={[
              ...COLUMNS,
              {
                Header: '',
                id: 'actions',
                className:'col-icons',
                accessor: (row) => (
                  <i ref={threeDotsRefActual} id='threeDotsActual' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenuActual(event, row)} />
                ),
              },
            ]}
            data={esquemaRealizadoActual && esquemaRealizadoActual?.detalle && esquemaRealizadoActual.detalle.length > 0 ? esquemaRealizadoActual.detalle : []}
            queryMD={queryActual}
            setQueryMD={setQueryActual}
            paginationOptions={paginationOptionsActual}
            handleContextMenu={handleContextMenuActual}
          />
        </TableContainer>
      </div>

      <ContextMenu
        showContextMenu={showContextMenuActual}
        setShowContextMenu={setShowContextMenuActual}
        actions={availableActionsActual()}
        currentPos={currentPosActual}
        ref={contextRefActual}
      />

      {values.tratamiento_01?.id_esquema_realizado_actual &&
        <div className='row my-8'>
          <div className='col'>
            <TextareaBS
              id='tratamiento_01.esquema_realizado_actual_observaciones'
              name='tratamiento_01.esquema_realizado_actual_observaciones'
              label='Observaciones de esquema indicado'
              disabled={
                !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
                !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
                !updating
              }
            />
          </div>
        </div>
      }
      {/* FIN - ESQUEMA REALIZADO ACTUAL - FIN */}

      <div className='row my-8'>
        <div className='col'>
          <TextareaBS
            id='tratamiento_01.justificacion'
            name='tratamiento_01.justificacion'
            label='Justificación / Resultados esperados'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      <h3 className='m-0'>Criterios para indicación</h3>
      <div className='row my-8'>
        <div className='col'>
          <SelectField
            id='tratamiento_01.receptores_hormonales'
            name='tratamiento_01.receptores_hormonales'
            label='Receptores hormonales'
            options={[
              { value: 'POSITIVOS', label: 'Positivos' },
              { value: 'NEGATIVOS', label: 'Negativos' },
            ]}
            placeholder='Seleccione una opción'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <SelectField
            id='tratamiento_01.her2n'
            name='tratamiento_01.her2n'
            label='her2n'
            options={[
              { value: 'POSITIVO', label: 'Positivo' },
              { value: 'NEGATIVO', label: 'Negativo' },
            ]}
            placeholder='Seleccione una opción'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <SelectField
            id='tratamiento_01.tratamiento_hormonal_previo'
            name='tratamiento_01.tratamiento_hormonal_previo'
            label='Tratamiento hormonal previo'
            options={[
              { value: 'SI', label: 'Sí' },
              { value: 'NO', label: 'No' },
            ]}
            placeholder='Seleccione una opción'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
              !updating
            }
          />
        </div>
      </div>

      {/* <div className='row my-8'>
        <TextareaBS
          id='tratamiento_01.resultados_esperados'
          name='tratamiento_01.resultados_esperados'
          label='Resultados esperados'
          disabled={
            !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
            !(values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.BORRADOR || values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO) ||
            !updating
          }
        />
      </div> */}
    </>
  )
}

export default Tratamiento01Tab