import { Link } from 'react-router-dom'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: <div className='text-center'>ID</div>,
      id: 'id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.LABORATORIOS}/${row.id}`}>
            {row.id}
          </Link>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>ID Usuario</div>,
      id: 'id_usuario',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.USERS}/${row.id_usuario}`}>
            {row.id_usuario}
          </Link>
        </div>
      ),
    },
    {
      Header: 'Nombre',
      id: 'nombre_completo',
      accessor: (row) => (
        <>
          {row.nombre}{row?.apellido ? ` ${row.apellido}` : ''}
        </>
      )
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => (
        <>
          {row?.email
            ? <>{row.email}</>
            : <div className='text-center'>-</div>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Celular</div>,
      id: 'telefono_movil',
      accessor: (row) => (
        <div className='text-center'>
          {row?.telefono_movil || '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Activo</div>,
      id: 'activo',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.activo)
            ? <span className='badge badge-success'>Sí</span>
            : <span className='badge badge-danger'>No</span>
          }
        </div>
      )
    }
  ]

  return {
    COLUMNS,
  }
}