import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useTratamientos, useEstudios, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import EstudioModal from './EstudioModal'
import { seguimientoInitialValues } from '../constants'
import { ESTADO_TRATAMIENTO, ROLES } from 'interfaces'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const EstudiosTab = ({ updating, handleGetTramiento }) => {
  const { id } = useParams()
  const { auth } = useAuth()
  const { values } = useFormikContext()
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [seguimiento, setSeguimiento] = useState(seguimientoInitialValues)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetEstudios, handleAddEstudio } = useEstudios()
  const [estudios, setEstudios] = useState([])

  const getEstudios = useCallback(async () => {
    try {
      let params = {
        id_tratamiento: id,
      }
      const response = await handleGetEstudios(params)
      setEstudios(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setEstudios([])
      setTotalCount(0)
    }
  }, [handleGetEstudios, id])

  const addEstudio = async (data) => {
    console.log(data)

    try {
      const data = new FormData()
      data.append('fecha', data.fecha)
      data.append('id_nombre_estudio', data.id_nombre_estudio)
      data.append('file', data.file)
      data.append('observacion', data.observacion)

      const response = await handleAddEstudio(id, null, data)

      toast.success(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    return actions
  }
  /* Fin menu contextual */

  const COLUMNS = useMemo(() => [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <>
          {row.id}
        </>
      ),
    },
    {
      Header: 'Estudio',
      id: 'id_nombre_estudio',
      accessor: (row) => (
        <>
          {row.nombre_estudio}
        </>
      ),
    },
    {
      Header: 'Observaciones',
      id: 'observacion',
      accessor: (row) => (
        <>
          {row.observaciones}
        </>
      ),
    },
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      ),
    },
  ])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getEstudios()
  }, [getEstudios])

  return (
    <>
      <EstudioModal
        show={modal}
        onClose={() => setModal(false)}
        onSubmit={values => {
          addEstudio(values)
          setModal(false)
        }}
      />

      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h3 className='m-0'>Estudios</h3>
        <Button
          type='button'
          variant='primary'
          onClick={() => {
            setModal(true)
          }}
          disabled={!auth.user.usuario_roles.some(r => r.id == ROLES.MEDICO || r.id == ROLES.ADMIN)}
        >
          Añadir estudio
        </Button>
      </div>

      <TableContainer>
        <CustomTable columns={COLUMNS} data={estudios || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default EstudiosTab