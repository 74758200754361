import service from 'services/service'

const PATH = {
  roles: '/roles',
}

export class Roles {
  static get = async (params) => {
    const response = await service.get(`${PATH.roles}`, {
      params
    })
    return response
  }

  static getRol = async (id, params) => {
    const response = await service.get(`${PATH.roles}/${id}`, {
      params
    })
    return response
  }

  static add = async (data) => {
    const response = await service.post(`${PATH.roles}`, data)
    return response
  }

  static update = async (id, data) => {
    const response = await service.patch(`${PATH.roles}/${id}`, data)
    return response
  }
}