import service from 'services/service'

const PATH = {
  pacientes: '/pacientes',
}

export class Pacientes {
  static getPacientes = async (params) => {
    const response = await service.get(`${PATH.pacientes}`, {
      params,
    })
    return response
  }

  static getPaciente = async (id, params) => {
    const response = await service.get(`${PATH.pacientes}/${id}`, {
      params
    })
    return response
  }

  static addPaciente = async (data) => {
    const response = await service.post(`${PATH.pacientes}`, data)
    return response
  }

  static updatePaciente = async (id, data) => {
    const response = await service.patch(`${PATH.pacientes}/${id}`, data)
    return response
  }
}