import { useState, useCallback } from 'react'

import { Financiadores } from 'services'

const useFinanciadores = () => {
  const [loading, setLoading] = useState(false)

  const handleGetFinanciadores = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Financiadores.get(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetFinanciador = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Financiadores.getFinanciador(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddFinanciador = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Financiadores.add(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateFinanciador = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Financiadores.update(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetFinanciadores,
    handleGetFinanciador,
    handleAddFinanciador,
    handleUpdateFinanciador,
  }
}

export default useFinanciadores;