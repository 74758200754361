import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useUsuarios, useRoles } from 'hooks'

import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'

import { usuarioInitialValues, usuarioSchema } from '../constants'
import routes from 'app/routing/routes'

const UsuarioCreacion = () => {
  const navigate = useNavigate()
  const submitRef = useRef(null)

  const { loading, handleAddUsuario } = useUsuarios()
  const { loading: loadingR, handleGetRoles } = useRoles()
  const [roles, setRoles] = useState([])

  const addUsuario = async (data) => {
    if (data.id_rol == '') {
      toast.error('Debe seleccionar un rol para el usuario')
      return
    }
    if (data.password !== data.password_confirm) {
      toast.error('Las contraseñas no coinciden')
      return
    }

    try {
      let values = {
        ...data,
      }
      delete values.password_confirm

      const response = await handleAddUsuario(values)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      navigate(routes.USERS)
    }
  }

  const getRoles = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
      }
      const response = await handleGetRoles(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.nombre }))

      setRoles(data)
    } catch (error) {
      toast.error(error.message)
      setRoles([])
    }
  }, [handleGetRoles])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  return (
    <DetailContainer
      title={'Crear usuario'}
      buttons={
        <div className='d-flex align-items-center justify-content-end'>
          <Button type='button' variant='secondary' onClick={() => navigate(-1)} className='me-3' disabled={loading || loadingR}><i className="ki-duotone ki-left" />Volver</Button>
          <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading || loadingR}><i className='bi bi-save me-2' />Guardar</Button>
        </div>
      }
    >
      <Formik
        initialValues={{
          ...usuarioInitialValues,
          password: '',
          password_confirm: '',
        }}
        validationSchema={usuarioSchema}
        onSubmit={addUsuario}
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='nombre' name='nombre' type='text' label='Nombre' />
                </div>

                <div className='col'>
                  <InputBS id='apellido' name='apellido' type='text' label='Apellido' />
                </div>

                <div className='col'>
                  <SelectField
                    id='id_rol'
                    name='id_rol'
                    label='Rol'
                    options={roles}
                  />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='telefono_movil' name='telefono_movil' type='text' label='Celular' />
                </div>

                <div className='col'>
                  <InputBS id='email' name='email' type='email' label='Email' />
                </div>

                <div className='col'>
                  <InputBS id='password' name='password' type='password' label='Contraseña' />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col'></div>
                <div className='col'></div>

                <div className='col'>
                  <InputBS id='password_confirm' name='password_confirm' type='password' label='Confirmar contraseña' />
                </div>
              </div>

              <div className='separator my-8' />

              <div className='text-end'>
                <Button ref={submitRef} type='submit' variant='primary'>Guardar</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default UsuarioCreacion