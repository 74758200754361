import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { useAuth } from 'app/modules/auth'

import { ROLES } from 'interfaces'
import routes from 'app/routing/routes'

const Actions = ({ usuario, updating, handleUpdating, submitRef, cancelRef }) => {
  const { auth } = useAuth()
  const navigate = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!updating
        ?
          <>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button
              variant='primary'
              type='button'
              onClick={handleUpdating}
              // disabled={usuario?.id_rol == ROLES.ADMIN}
            >
              <i className='bi bi-pencil' />
              Editar
            </Button>
          </>
        :
          <>
            <Button className='me-3' variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button variant='primary' type='button' onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>
          </>
      }
    </div>
  )
}

export default Actions