import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useTratamientos, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import SeguimientoModal from './SeguimientoModal'
import { SeguimientoColumns } from './SeguimientoColumns'
import { seguimientoInitialValues } from '../constants'
import { ESTADO_TRATAMIENTO, ROLES } from 'interfaces'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const SeguimientosTab = ({ handleGetTratamiento }) => {
  const { auth } = useAuth()
  const { COLUMNS } = SeguimientoColumns()

  const { id } = useParams()
  const navigate = useNavigate()
  const { values } = useFormikContext()

  const [modal, setModal] = useState(false)
  const [seguimiento, setSeguimiento] = useState(seguimientoInitialValues)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetSeguimientos, handleAddSeguimiento, handleAutorizarTratamiento, handleFinalizarTratamiento, handleTratamientoPendienteMedico, handleAnularTratamiento } = useTratamientos()
  const [seguimientos, setSeguimientos] = useState([])

  const getSeguimientos = useCallback(async () => {
    try {
      const response = await handleGetSeguimientos(id)
      setSeguimientos(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setSeguimientos([])
      setTotalCount(0)
    }
  }, [handleGetSeguimientos, id])

  const addSeguimiento = async (data) => {
    const text = Boolean(data?.continua_tratamiento)
      ? '¿Quiere añadir el seguimiento al tratamiento? El tratamiento pasará a auditoría, así que no podrá hacer más cambios.'
      : 'Si el paciente no continúa con el tratamiento, se finalizará el mismo. ¿Está de acuerdo?'

    const resp = await Swal.fire({
      title: 'Advertencia',
      text: text,
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: Boolean(data?.continua_tratamiento) ? 'Si, añadir' : 'Sí, finalizar',
      customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      try {
        let values = {
          ...data,
          continua_tratamiento: Boolean(data?.continua_tratamiento),
          proximo_control: Boolean(data?.continua_tratamiento) ? moment(data.proximo_control).format('YYYY-MM-DD') : null,
          mismo_esquema: Boolean(data?.mismo_esquema),
        }
        const response = await handleAddSeguimiento(id, values)

        toast.success(response.data.message)
        setModal(false)
      } catch (error) {
        toast.error(error.message)
      } finally {
        getSeguimientos()
        handleGetTratamiento()
      }
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => {
        setSeguimiento(currentRow)
        setModal(true)
      }
    })

    if (
      auth.user.usuario_roles.some(r => r.id == ROLES.AUDITOR || r.id == ROLES.ADMIN) &&
      values.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR &&
      seguimientos && seguimientos.length > 0 && seguimientos[0]?.id == currentRow?.id
    ) {
      actions.push({
        label: 'Autorizar',
        icon: 'bi bi-check-all text-success',
        action: () => actionAutorizarTratamiento
      })

      actions.push({
        label: 'Finalizar',
        icon: 'bi bi-check-circle text-primary',
        action: () => actionFinalizarTratamiento
      })

      actions.push({
        label: 'Faltan datos',
        icon: 'bi bi-info-circle text-warning',
        action: () => actionTratamientoPendienteMedico
      })

      actions.push({
        label: 'Rechazar',
        icon: 'bi bi-x-circle text-danger',
        action: () => actionAnularTratamiento
      })
    }

    return actions
  }

  const actionAutorizarTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Autorizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, autorizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
		})

    if (resp.isConfirmed) {
      try {
        const response = await handleAutorizarTratamiento(id)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionFinalizarTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Finalizar el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, finalizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value,
      }

      try {
        const response = await handleFinalizarTratamiento(id, data)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionTratamientoPendienteMedico = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Desea que el médico complete información en el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, actualizar',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value,
      }

      try {
        const response = await handleTratamientoPendienteMedico(id, data)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionAnularTratamiento = async () => {
    const resp = await Swal.fire({
			title: 'Advertencia',
			text: '¿Anular el tratamiento?',
			icon: 'warning',
			showDenyButton: true,
			denyButtonText: 'No, cancelar',
			confirmButtonText: 'Sí, anular',
			customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
			reverseButtons: true,
      input: 'textarea', // Agrega esta línea
      inputPlaceholder: 'Escribe tu observación aquí...', // Placeholder para el textarea
      inputAttributes: {
        'aria-label': 'Escribe tu observación aquí'
      },
		})

		if (resp.isConfirmed) {
      const data = {
        observaciones: resp.value,
      }

      try {
        const response = await handleAnularTratamiento(id, data)
        toast.success(response.data.message)

        navigate(routes.TRATAMIENTOS)
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      ),
    },
  ], [])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getSeguimientos()
  }, [getSeguimientos])

  return (
    <>
      <SeguimientoModal
        values={seguimiento}
        id_estado_tratamiento={values.tratamiento_evento.id_estado_tratamiento}
        show={modal}
        onClose={() => {
          setModal(false)
          setSeguimiento(seguimientoInitialValues)
        }}
        onSubmit={addSeguimiento}
        refresh={() => {
          handleGetTratamiento()
          getSeguimientos()
        }}
      />

      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h3 className='m-0'>Seguimientos</h3>
        <Button
          type='button'
          variant='primary'
          onClick={() => {
            setSeguimiento(seguimientoInitialValues)
            setModal(true)
          }}
          disabled={!auth.user.usuario_roles.some(r => r.id == ROLES.MEDICO || r.id == ROLES.ADMIN) || values.tratamiento_evento.id_estado_tratamiento != ESTADO_TRATAMIENTO.EN_PROGRESO}
        >
          Añadir seguimiento
        </Button>
      </div>

      <TableContainer>
        <CustomTable columns={columns} data={seguimientos || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default SeguimientosTab