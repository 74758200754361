import { Link } from 'react-router-dom'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.TRATAMIENTOS}/${row.id}`}>
          {row.id}
        </Link>
      ),
    },
    {
      Header: 'Tipo tratamiento',
      id: 'id_tipo_tratamiento',
      accessor: (row) => (
        <>
          {row.tipo_tratamiento_label}
        </>
      ),
    },
    {
      Header: <div className='text-center'>Fecha</div>,
      id: 'fecha_creacion',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.fecha_creacion).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      Header: <div className=''>Financiador</div>,
      id: 'id_financiador',
      accessor: (row) => (
        <div className={!row?.id_financiador ? 'text-center' : ''}>
          {row?.id_financiador ? row.financiador_nombre : '-'}
        </div>
      ),
    },
    {
      Header: <div className=''>Laboratorio</div>,
      id: 'id_laboratorio',
      accessor: (row) => (
        <div className={!row?.id_laboratorio ? 'text-center' : ''}>
          {row?.id_laboratorio ? row.laboratorio_nombre : '-'}
        </div>
      ),
    },
    {
      Header: 'Paciente',
      id: 'id_paciente',
      accessor: (row) => (
        <>
          {row.paciente_nombre_completo}
        </>
      )
    },
    {
      Header: <div className='text-center'>Diagnóstico</div>,
      id: 'id_cie10_cabecera',
      accessor: (row) => (
        <div className='text-center'>
          <Tooltip text={row.cie10_cabecera_descripcion}>
            <p className='m-0 p-0'>
              {row.id_cie10_cabecera}
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Detalle</div>,
      id: 'id_cie10_detalle',
      accessor: (row) => (
        <div className='text-center'>
          {row?.id_cie10_detalle
            ?
              <Tooltip text={row.cie10_detalle_descripcion}>
                <p className='m-0 p-0'>{row.id_cie10_detalle}</p>
              </Tooltip>
            : <>{'-'}</>
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Patología</div>,
      id: 'id_patologia',
      accessor: (row) => (
        <div className='text-center'>
          {row.patologia_label}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Inicio de enfermedad</div>,
      id: 'fecha_inicio_enfermedad',
      accessor: (row) => (
        <div className='text-center'>
          {row?.tratamiento_01?.fecha_inicio ? moment(row.tratamiento_01?.fecha_inicio).format('DD/MM/YYYY') : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id: 'estado_tratamiento',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.tratamiento_evento.estado_tratamiento_label}
        </span>
      )
    }
  ]

  return {
    COLUMNS,
  }
}