import { useState, useCallback } from 'react'

import { Pacientes } from 'services'

const usePacientes = () => {
  const [loading, setLoading] = useState(false)

  const handleGetPacientes = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Pacientes.getPacientes(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPaciente = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Pacientes.getPaciente(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddPaciente = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Pacientes.addPaciente(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePaciente = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Pacientes.updatePaciente(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetPacientes,
    handleGetPaciente,
    handleAddPaciente,
    handleUpdatePaciente,
  }
}

export default usePacientes;