import moment from 'moment'

import { Tooltip } from 'components'

import { respuestas } from '../constants'

export const SeguimientoColumns = () => {
  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <>
          {row.id}
        </>
      ),
    },
    {
      Header: <div className='text-center'>Fecha creación</div>,
      id: 'fecha_creacion',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.fecha_creacion).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Fecha inicio</div>,
      id: 'fecha_inicio',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.fecha_inicio).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Fecha finalización</div>,
      id: 'fecha_finalizacion',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.fecha_finalizacion).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Ciclos</div>,
      id: 'ciclos',
      accessor: (row) => (
        <div className='text-center'>
          {row?.ciclos || '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Respuesta</div>,
      id: 'respuesta',
      accessor: (row) => (
        <div className='text-center'>
          {row?.respuesta ? respuestas.find(r => r.value == row.respuesta).label : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>¿Mismo esquema?</div>,
      id: 'mismo_esquema',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row?.mismo_esquema)
            ? <span className='badge badge-success'>Sí</span>
            : <span className='badge badge-danger'>No</span>
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>¿Continúa tratamiento?</div>,
      id: 'continua_tratamiento',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row?.continua_tratamiento)
            ? <span className='badge badge-success'>Sí</span>
            : <span className='badge badge-danger'>No</span>
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Próximo control</div>,
      id: 'proximo_control',
      accessor: (row) => (
        <div className='text-center'>
          {row?.proximo_control ? moment(row.proximo_control).format('DD/MM/YYYY') : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Observaciones</div>,
      id: 'observacion',
      accessor: (row) => (
        <div className='text-center'>
          <Tooltip text={row?.observacion}>
            <i className='bi bi-info-circle text-primary' />
          </Tooltip>
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}