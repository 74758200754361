import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Card } from 'react-bootstrap'

import { MapDirection } from 'components'

import { addAddressInitialValues, addAddressSchema } from './constants'

const AddressModal = ({ show, onClose, values = addAddressInitialValues, onSave, submodal = false }) => {
  const [initialValues, setInitialValues] = useState(addAddressInitialValues)

  useEffect(() => {
    setInitialValues({
      address: {
        ...addAddressInitialValues.address,
        ...values?.address,
      },
    })
  }, [setInitialValues, values])

  if (!show) return null

  return (
    <Modal size='xl' show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>{values?.address?.id ? `Editar dirección #${values.address.id}` : 'Nueva dirección'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={addAddressSchema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors, handleSubmit }) => {
            return (
              <>
                <MapDirection />

                <Card.Footer className='d-flex align-items-center justify-content-end'>
                  <Button type='button' variant='secondary' className='me-3' onClick={onClose}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddressModal