import service from 'services/service'

const PATH = {
  tratamientos: '/tratamientos',
  tiposTratamiento: '/tratamientos/tipos-tratamiento',
  estadificaciones: '/tratamientos/estadificaciones',
  reporteTratamientos: '/tratamientos/reporte',
}

export class Tratamientos {
  static getTratamientos = async (params) => {
    const response = await service.get(PATH.tratamientos, {
      params
    })
    return response
  }

  static getTratamiento = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}`, {
      params
    })
    return response
  }

  static addTratamiento = async (data) => {
    const response = await service.post(`${PATH.tratamientos}`, data)
    return response
  }

  static updateTratamiento = async (id, data) => {
    const response = await service.patch(`${PATH.tratamientos}/${id}`, data)
    return response
  }

  static getDrogasRel = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/drogas-rel`, {
      params
    })
    return response
  }

  static addDrogasRel = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/drogas-rel`, data)
    return response
  }

  static removeDrogaRel = async (id, id_droga_rel) => {
    const response = await service.delete(`${PATH.tratamientos}/${id}/drogas-rel/${id_droga_rel}`)
    return response
  }

  static addTratamiento01 = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/tratamiento-01`, data)
    return response
  }

  static getEsquemaRealizadoInicial = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/esquema-realizado-inicial`, {
      params,
    })

    return response
  }

  static getEsquemaRealizadoActual = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/esquema-realizado-actual`, {
      params,
    })

    return response
  }

  static getEsquemaRealizadoSeguimiento = async (id, id_seguimiento, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/seguimiento/${id_seguimiento}/esquema-realizado`, {
      params
    })
    return response
  }

  static addEsquemaRealizado = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/esquema-realizado`, data)
    return response
  }

  static addDetalleEsquemaRealizado = async (id, id_seguimiento, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/detalle-esquema-realizado`, data)
    return response
  }

  static addEsquemaRealizadoDetalle = async (id, id_esquema_realizado, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/esquema-realizado/${id_esquema_realizado}/detalle`, data)
    return response
  }

  static addDetalleEsquemaRealizadoSeguimiento = async (id, id_seguimiento, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/seguimiento/${id_seguimiento}/esquema-realizado-detalle`, data)
    return response
  }

  static deleteDetalleEsquemaRealizado = async (id_tratamiento, id_esquema_realizado, id_detalle) => {
    const response = await service.delete(`${PATH.tratamientos}/${id_tratamiento}/esquema-realizado/${id_esquema_realizado}/detalle/${id_detalle}`)
    return response
  }

  static getEstudios = async (id_tratamiento, id_seguimiento, params) => {
    let data = {
      id_seguimiento: id_seguimiento,
    }

    const response = await service.get(`${PATH.tratamientos}/${id_tratamiento}/estudios`, data, {
      params,
    })

    return response
  }

  static addEstudio = async (id_tratamiento, id_seguimiento, data) => {
    let newData = {
      ...data,
      id_seguimiento: id_seguimiento,
    }

    const response = await service.post(`${PATH.tratamientos}/${id_tratamiento}`, newData)

    return response
  }

  static removeEstudio = async (id, id_tratamiento, id_seguimiento) => {
    let data = {
      id_seguimiento: id_seguimiento
    }

    const response = await service.delete(`${PATH.tratamientos}/${id_tratamiento}/estudio/${id}`, data)

    return response
  }

  static getSeguimientos = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/seguimientos`, {
      params
    })
    return response
  }

  static addSeguimiento = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/seguimiento`, data)
    return response
  }

  static updateSeguimiento = async (id, id_seguimiento, data) => {
    const response = await service.patch(`${PATH.tratamientos}/${id}/seguimiento/${id_seguimiento}`, data)
    return response
  }

  static auditarTratamiento = async (id) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/auditar`)
    return response
  }

  static autorizarTratamiento = async (id) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/autorizar`)
    return response
  }

  static finalizarTratamiento = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/finalizar`, data)
    return response
  }

  static tratamientoPendienteMedico = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/pendiente`, data)
    return response
  }

  static anularTratamiento = async (id, data) => {
    const response = await service.post(`${PATH.tratamientos}/${id}/anular`, data)
    return response
  }

  static getTiposTratamiento = async (params) => {
    const response = await service.get(PATH.tiposTratamiento, {
      params,
    })
    return response
  }

  static getEstadificaciones = async (params) => {
    const response = await service.get(PATH.estadificaciones, {
      params,
    })
    return response
  }

  static getTratamientoEventos = async (id, params) => {
    const response = await service.get(`${PATH.tratamientos}/${id}/eventos`, {
      params
    })
    return response
  }

  static getReporteTratamientos = async (params) => {
    const response = await service.get(PATH.reporteTratamientos, {
      params
    })
    return response
  }
}