import { useState, useCallback, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useTratamientos } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { COLUMNS } from './TratamientoEventosColumns'

import { statusLabelColor } from '../constants'

const TratamientoEventosModal = ({ id, show, onClose }) => {
  const { handleGetTratamientoEventos } = useTratamientos()
  const [eventos, setEventos] = useState([])

  const getEventos = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC'
      }

      const response = await handleGetTratamientoEventos(id, params)
      const data = response.data.result.map(d => ({ ...d, label_color: statusLabelColor(d.id_estado_tratamiento) }))

      setEventos(data)
    } catch (error) {
      setEventos([])
      toast.error(error.message)
    }
  }, [id, handleGetTratamientoEventos])

  useEffect(() => {
    getEventos()
  }, [getEventos])

  useEffect(() => {}, [id])

  if (!show || !id) return null

  return (
    <Modal show={show} onHide={onClose} size='lg' backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Eventos del Tratamiento #{id}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col'>
            <h2>Eventos</h2>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <TableContainer>
              <CustomTable columns={COLUMNS} data={eventos} />
            </TableContainer>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TratamientoEventosModal