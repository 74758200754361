import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {MutableRefObject, useEffect, useRef} from 'react'
import {ToggleComponent} from '../../../assets/ts/components'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const ENV = process.env.REACT_APP_ENV

const SidebarTitle = (props: PropsType) => {
  const {config} = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])


  return (
    <div className={`app-sidebar-logo px-6 ${ENV?.toUpperCase()=='DEV'?'bg-green':ENV?.toUpperCase()=='QA'?'bg-yellow':'bg-white'}`} id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        <h1 className={`${config.layoutType ? 'text-tertiary' : 'text-white'} m-0`} style={{ fontFamily: 'Assistant, sans-serif' }}>Riesgo Compartido</h1>
      </Link>
    </div>
  )
}

export {SidebarTitle}
