import { useState, useCallback } from 'react'

import { Drogas } from 'services'

const useDrogas = () => {
  const [loading, setLoading] = useState(false)

  const handleGetDrogas = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Drogas.getDrogas(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDroga = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Drogas.getDroga(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDroga = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Drogas.addDroga(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDroga = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Drogas.updateDroga(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetDrogas,
    handleGetDroga,
    handleAddDroga,
    handleUpdateDroga,
  }
}

export default useDrogas;