import { useState, useCallback } from 'react'

import { Patologias } from 'services'

const usePatologias = () => {
  const [loading, setLoading] = useState(false)

  const handleGetPatologias = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Patologias.getPatologias(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPatologia = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Patologias.getPatologia(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddPatologia = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Patologias.addPatologia(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePatologia = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Patologias.updatePatologia(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetPatologias,
    handleGetPatologia,
    handleAddPatologia,
    handleUpdatePatologia,
  }
}

export default usePatologias;