import { useState, useCallback } from 'react'

import { Usuarios } from 'services'

const useUsuarios = () => {
  const [loading, setLoading] = useState(false)

  const handleGetUsuarios = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Usuarios.get(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetUsuario = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Usuarios.getUsuario(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddUsuario = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Usuarios.add(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateUsuario = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Usuarios.update(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleResetPassword = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Usuarios.resetPassword(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePassword = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Usuarios.updatePassword(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetUsuarios,
    handleGetUsuario,
    handleAddUsuario,
    handleUpdateUsuario,
    handleResetPassword,
    handleUpdatePassword,
  }
}

export default useUsuarios;