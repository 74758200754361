import { FC } from 'react'
import { useField  } from 'formik'
import { Form } from 'react-bootstrap';
import { SwitchI } from './types';

const SwitchField: FC<SwitchI> = ({
  id,
  name,
  checked = false,
  styles = '',
  required = false,
  disabled = false,
  handleChange = () => {}
}) => {
  const [field, , helpers] = useField<boolean>(name ? name : id)

  const handleSwitchChange = () => {
    helpers.setValue(!field.value)
    handleChange()
  }

  return (
    <Form.Group>
      <Form.Check
        id={id}
        type="switch"
        name={name ? name : id}
        className={styles}
        checked={field.value}
        onChange={handleSwitchChange}
        required={required}
        disabled={disabled}
      />
    </Form.Group>
  )
};

export default SwitchField;
