import service from 'services/service'

const PATH = {
  usuarios: '/usuarios',
}

export class Usuarios {
  static get = async (params) => {
    const response = await service.get(`${PATH.usuarios}`, {
      params
    })
    return response
  }

  static getUsuario = async (id, params) => {
    const response = await service.get(`${PATH.usuarios}/${id}`, {
      params
    })
    return response
  }

  static add = async (data) => {
    const response = await service.post(`${PATH.usuarios}`, data)
    return response
  }

  static update = async (id, data) => {
    const response = await service.patch(`${PATH.usuarios}/${id}`, data)
    return response
  }

  static resetPassword = async (id) => {
    const response = await service.get(`${PATH.usuarios}/${id}/reset-password`)
    return response
  }

  static updatePassword = async (id, data) => {
    const response = await service.post(`${PATH.usuarios}/${id}/new-password`, data)
    return response
  }
}