import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import { AssignmentOutlined } from '@mui/icons-material'

import { useAuth } from 'app/modules/auth'
import { useTratamientos } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import Actions from './Actions'
import GeneralTab from './GeneralTab'
import Tratamiento01Tab from './Tratamiento01Tab'
import SeguimientosTab from './SeguimientosTab'
import EstudiosTab from './EstudiosTab'

import { detalleTratamientoInitialValues, tratamientoUpdateSchema, tratamientoAdapter, updateTratamientoAdapter, statusLabelColor } from '../constants'
import { ESTADO_TRATAMIENTO } from 'interfaces'

import routes from 'app/routing/routes'

const TratamientoDetalle = () => {
  const { id } = useParams()
  const { auth } = useAuth()

  const navigate = useNavigate()
  const [updating, setUpdating] = useState(false)
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetTratamiento, handleUpdateTratamiento } = useTratamientos()
  const [tratamiento, setTratamiento] = useState(detalleTratamientoInitialValues)

  const getTratamiento = useCallback(async () => {
    try {
      const response = await handleGetTratamiento(id)
      let data = tratamientoAdapter(response.data.result)
      data.label_color = statusLabelColor(data.tratamiento_evento.id_estado_tratamiento)

      setTratamiento(data)
    } catch (error) {
      toast.error(error.message)
      setTratamiento(detalleTratamientoInitialValues)
    }
  }, [handleGetTratamiento, id])

  const updateTratamiento = async (data) => {
    if (data.id_paciente == '') {
      toast.error('Debe seleccionar un paciente para el tratamiento')
      return
    }
    // if (data.tratamiento_01.fecha_inicio == '') {
    //   toast.error('Debe seleccionar una fecha de inicio en Tratamiento Oncológico')
    //   return
    // }
    // if (data.tratamiento_01.id_estadificacion_inicial == '') {
    //   toast.error('Debe seleccionar la estadificación inicial en Tratamiento Oncológico')
    //   return
    // }

    try {
      const values = updateTratamientoAdapter(data)
      const response = await handleUpdateTratamiento(id, values)

      toast.success(response.data.message)
      setUpdating(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getTratamiento()
    }
  }

  useEffect(() => {
    getTratamiento()
  }, [getTratamiento])

  return (
    <DetailContainer
      title={`Tratamiento #${id}`}
      buttons={
        <Actions
          loading={loading}
          tratamiento={tratamiento}
          handleGetTratamiento={getTratamiento}
          updating={updating}
          handleUpdating={setUpdating}
          submitRef={submitRef}
          cancelRef={cancelRef}
        />
      }
      // statusV2={
      //   <div className='mt-3'>
      //     <span className={`badge ${tratamiento.label_color} w-90 justify-content-center fw-normal`}>{tratamiento?.tratamiento_evento?.estado_tratamiento_label}</span>

      //     {tratamiento?.tratamiento_evento.id_estado_tratamiento == ESTADO_TRATAMIENTO.PENDIENTE_MEDICO &&
      //       <div className='mt-3' style={{ width: '250px' }}>
      //         <p><strong>Observación:</strong> {tratamiento?.tratamiento_evento?.observacion}</p>
      //       </div>
      //     }
      //   </div>
      // }
      statusV2={
        <>
          <div className={`badge ${tratamiento.label_color} justify-content-center fw-normal`} style={{ width: "150px" }}>
            {tratamiento?.tratamiento_evento?.estado_tratamiento_label}
          </div>

          <div className='mt-7'>
            <table>
              <tbody>
                {/* <tr>
                  <td style={{ width: "120px" }}>F. carga:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.fecha_creacion ? moment(tratamiento.fecha_creacion).format("DD/MM/YYYY") : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>F. inicio enfermedad:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.fecha_inicio_enfermedad ? moment(tratamiento.fecha_inicio_enfermedad).format("DD/MM/YYYY") : ''}</span></td>
                </tr> */}
                <tr>
                  <td style={{ width: "120px" }}>Financiador:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.id_financiador ? tratamiento.financiador_nombre : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Laboratorio:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.id_laboratorio ? tratamiento.laboratorio_nombre : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Paciente:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.id_paciente ? tratamiento.paciente_nombre_completo : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Patología:</td>
                  <td><span className='fw-bold ps-5'>{tratamiento?.id_patologia ? tratamiento.patologia_label : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Diagnóstico:</td>
                  <td style={{ width: '400px' }}><span className='fw-bold ps-5'>{tratamiento?.id_cie10_cabecera ? `${tratamiento.id_cie10_cabecera} - ${tratamiento.cie10_cabecera_descripcion}` : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Detalle:</td>
                  <td style={{ width: '400px' }}><span className='fw-bold ps-5'>{tratamiento?.id_cie10_detalle ? `${tratamiento.id_cie10_detalle} - ${tratamiento.cie10_detalle_descripcion}` : ''}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "120px" }}>Observación:</td>
                  <td style={{ width: '400px' }}><span className='fw-bold ps-5'>{tratamiento?.tratamiento_evento?.observacion}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      }
    >
      <Formik
        initialValues={tratamiento}
        validationSchema={tratamientoUpdateSchema}
        onSubmit={updateTratamiento}
        enableReinitialize
      >
        {({ values, errors, handleReset }) => {
          console.log(values)
          console.log(errors)
          return (
            <Form>
              <TabsContainer
                initialValue='general'
                tabs={[
                  { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true },
                  {
                    value: 'tratamiento-01',
                    label: 'Tratamiento Oncológico',
                    icon: <AssignmentOutlined />,
                    show: true,
                    errors:
                      values.tratamiento_01?.fecha_inicio == '' ||
                      values.tratamiento_01?.id_estadificacion_inicial == '',
                    disabled: loading
                  },
                  { value: 'seguimientos', label: 'Seguimientos', icon: <AssignmentOutlined />, show: true, disabled: loading, },
                  { value: 'estudios', label: 'Estudios', icon: <AssignmentOutlined />, show: true, disabled: loading },
                ]}
                tabsContent={[
                  <GeneralTab updating={updating} />,
                  <Tratamiento01Tab updating={updating} handleGetTratamiento={getTratamiento} />,
                  <SeguimientosTab updating={updating} handleGetTratamiento={getTratamiento} />,
                  <EstudiosTab updating={updating} handleGetTratamiento={getTratamiento} />,
                ]}
              />

              {updating &&
                <>
                  <div className='separator my-8'></div>

                  <div className='text-end'>
                    <>
                      <Button
                        ref={cancelRef}
                        type='button'
                        variant='secondary'
                        className='me-3'
                        onClick={() => {
                          handleReset()
                          setUpdating(false)
                        }}
                        disabled={loading}
                      >
                        <i className='bi bi-slash-circle' />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} type='submit' variant='primary' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </>
                  </div>
                </>
              }
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default TratamientoDetalle