import service from 'services/service'

const PATH = {
  financiadores: '/financiadores',
}

export class Financiadores {
  static get = async (params) => {
    const response = await service.get(`${PATH.financiadores}`, {
      params
    })
    return response
  }

  static getFinanciador = async (id, params) => {
    const response = await service.get(`${PATH.financiadores}/${id}`, {
      params
    })
    return response
  }

  static add = async (data) => {
    const response = await service.post(`${PATH.financiadores}`, data)
    return response
  }

  static update = async (id, data) => {
    const response = await service.patch(`${PATH.financiadores}/${id}`, data)
    return response
  }
}