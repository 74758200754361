import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { usePacientes, useObrasSociales } from 'hooks'

import { DetailContainer } from 'containers'
import { AddressModal } from 'containers/Modals'
import { InputBS, SelectField, FormTable } from 'components'

import Actions from './Actions'
import { pacienteInitialValues, pacienteAdapter, updatePacienteAdapter, pacienteSchema, tiposDocumento } from '../constants'
import { ROLES } from 'interfaces'

const PacienteDetalle = () => {
  const { id } = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()

  const [updating, setUpdating] = useState(false)
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetPaciente, handleUpdatePaciente } = usePacientes()
  const [paciente, setPaciente] = useState(pacienteInitialValues)
  const { loading: loadingOS, handleGetObrasSociales } = useObrasSociales()
  const [obrasSociales, setObrasSociales] = useState([])
  const [modal, setModal] = useState(false)

  const getPaciente = useCallback(async () => {
    try {
      const response = await handleGetPaciente(id)
      const data = pacienteAdapter(response.data.result)

      setPaciente(data)
    } catch (error) {
      toast.error(error.message)
      navigate(-1)
    }
  }, [handleGetPaciente, id])

  const updatePaciente = async (data) => {
    if (data.address?.route == '') {
      toast.error('Debe ingresar una dirección para el paciente')
      return
    }

    const values = updatePacienteAdapter(data)

    try {
      const response = await handleUpdatePaciente(id, values)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setUpdating(false)
      getPaciente()
    }
  }

  const getObrasSociales = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
      }
      const response = await handleGetObrasSociales(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.nombre }))

      setObrasSociales(data)
    } catch (error) {
      toast.error(error.message)
      setObrasSociales([])
    }
  }, [handleGetObrasSociales])

  useEffect(() => {
    getPaciente()
    getObrasSociales()
  }, [getPaciente, getObrasSociales])

  return (
    <DetailContainer
      title={`Paciente #${id}${paciente ? ` - ${paciente.nombre} ${paciente.apellido}` : ''}`}
      buttons={<Actions updating={updating} handleUpdating={setUpdating} submitRef={submitRef} cancelRef={cancelRef} />}
    >
      <Formik
        initialValues={paciente}
        validationSchema={pacienteSchema}
        onSubmit={updatePaciente}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, handleReset }) => {
          return (
            <Form>
              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='nombre' name='nombre' type='text' label='Nombre' disabled={!updating} />
                </div>

                <div className='col'>
                  <InputBS id='apellido' name='apellido' type='text' label='Apellido' disabled={!updating} />
                </div>

                <div className='col'>
                  <SelectField
                    id='tipo_documento'
                    name='tipo_documento'
                    label='Tipo documento'
                    options={tiposDocumento}
                    disabled={!updating}
                  />
                </div>

                <div className='col'>
                  <InputBS id='documento' name='documento' type='text' label='Documento' disabled={!updating} />
                </div>
              </div>

              <div className='row my-8'>
                <div className='col'>
                  <InputBS id='email' name='email' type='email' label='Email' disabled={!updating} />
                </div>

                <div className='col'>
                  <InputBS id='telefono_fijo' name='telefono_fijo' type='text' label='Teléfono' disabled={!updating} />
                </div>

                <div className='col'>
                  <InputBS id='telefono_movil' name='telefono_movil' type='text' label='Celular' disabled={!updating} />
                </div>
              </div>

              <AddressModal
                show={modal}
                onClose={() => setModal(false)}
                values={values}
                onSave={data => {
                  let updatedValues = {
                    ...values,
                    address: data.address
                  }
                  setModal(false)
                  updatePaciente(updatedValues)
                }}
              />

              <div className='separator my-8' />
              <div className='mb-8 d-flex flex-row justify-content-between align-items-center'>
                <h2 className='m-0'>Dirección</h2>
                <Button
                  className='BSButton'
                  variant='outline-primary'
                  onClick={() => setModal(true)}
                  disabled={!auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO))}
                >
                  Editar dirección
                </Button>
              </div>

              {values?.address?.id &&
                <FormTable
                  columns={[
                    { header: 'Calle' },
                    { header: 'CP' },
                    { header: values.address.custom_address_level1_label },
                    { header: values.address.custom_address_level2_label },
                    { header: 'País' },
                    { header: '', styles: 'text-center' },
                  ]}
                  responsive={false}
                >
                  <tr>
                    <td className='align-middle'>{values.address.route} {values.address.street_number}</td>
                    <td className='align-middle'>{values.address.postal_code}{values.address.postal_code_suffix}</td>
                    <td className='align-middle'>{values.address.custom_address_level1}</td>
                    <td className='align-middle'>{values.address.custom_address_level2}</td>
                    <td className='align-middle'>{values.address.country}</td>
                    <td className='align-middle text-center'>
                      <button
                        type='button'
                        className='btn btn-icon'
                        onClick={() => setModal(true)}
                        disabled={!auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO))}
                      >
                        <i className='fas fa-pencil text-primary'></i>
                      </button>
                    </td>
                  </tr>
                </FormTable>
              }
              <div className='separator my-8' />

              <div className='row my-8'>
                <div className='col'>
                  <SelectField
                    id='id_obra_social'
                    name='id_obra_social'
                    label='Obra social'
                    options={obrasSociales}
                    disabled={!updating}
                  />
                </div>

                <div className='col'>
                  <InputBS id='numero_afiliado' name='numero_afiliado' type='text' label='Nro. afiliado' disabled={!updating} />
                </div>
              </div>

              <div className='separator my-8' />

              <div className='text-end'>
                {updating &&
                  <>
                    <Button
                      ref={cancelRef}
                      type='button'
                      variant='secondary'
                      onClick={() => {
                        setUpdating(false)
                        handleReset()
                      }}
                      className='me-3'
                      disabled={loading}
                    >
                      <i className='bi bi-slash-circle' />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} type='submit' variant='primary' disabled={loading || loadingOS}>
                      <i className='bi bi-save me-2' />
                      Guardar
                    </Button>
                  </>
                }
              </div>
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default PacienteDetalle