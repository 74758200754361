import { useState, useCallback } from 'react'

import { Diagnosticos } from 'services'

const useDiagnosticos = () => {
  const [loading, setLoading] = useState(false)

  const handleGetDiagnosticos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.getDiagnosticos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDiagnostico = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.getDiagnostico(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDiagnostico = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.addDiagnostico(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDiagnostico = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.updateDiagnostico(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCie10Cabeceras = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.getCie10Cabeceras(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCie10Cabecera = useCallback(async (id) => { // id = cod_3
    try {
      setLoading(true)
      const response = await Diagnosticos.getCie10Cabecera(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCie10Detalles = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Diagnosticos.getCie10Detalles(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCie10Detalle = useCallback(async (id) => { // id = cod_4
    try {
      setLoading(true)
      const response = await Diagnosticos.getCie10Detalle(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetDiagnosticos,
    handleGetDiagnostico,
    handleAddDiagnostico,
    handleUpdateDiagnostico,
    handleGetCie10Cabeceras,
    handleGetCie10Cabecera,
    handleGetCie10Detalles,
    handleGetCie10Detalle,
  }
}

export default useDiagnosticos;