import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'

import { useUsuarios, useRoles } from 'hooks'

import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'

import Actions from './Actions'
import ResetPasswordModal from './ResetPasswordModal'
import { usuarioInitialValues, usuarioDetalleSchema, usuarioAdapter, updateUsuarioAdapter } from '../constants'

import { ROLES } from 'interfaces'

const UsuarioDetalle = () => {
  const { id } = useParams()
  const { auth, saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)

  const [updating, setUpdating] = useState(false)
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetUsuario, handleUpdateUsuario, handleUpdatePassword } = useUsuarios()
  const { loading: loadingR, handleGetRoles } = useRoles()
  const [usuario, setUsuario] = useState(usuarioInitialValues)
  const [roles, setRoles] = useState([])

  const getUsuario = useCallback(async () => {
    try {
      const response = await handleGetUsuario(id)
      const data = usuarioAdapter(response.data.result)

      setUsuario(data)
    } catch (error) {
      toast.error(error.message)
      navigate(-1)
    }
  }, [handleGetUsuario, id])

  const getRoles = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
      }
      const response = await handleGetRoles(params)
      const data = response.data.result

      setRoles(data.map(d => ({ value: d.id, label: d.nombre })))
    } catch (error) {
      toast.error(error.message)
      setRoles([])
    }
  }, [handleGetRoles])

  const updateUsuario = async (usuario) => {
    try {
      let data = updateUsuarioAdapter(usuario)
      const response = await handleUpdateUsuario(id, data)

      toast.success(response.data.message)
      setUpdating(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getUsuario()
    }
  }

  const updatePassword = async (values) => {
    if (values.password !== values.password2) {
      toast.error('Las contraseñas deben coincidir')
      return
    }

    try {
      const data = {
        password: values.password,
      }
      const response = await handleUpdatePassword(id, data)
      toast.success(response.data.message)

      if (auth.user.usuario_email == usuario.email) {
        saveAuth({
          ...auth,
          user : {
            ...auth.user,
            usuario_password_temp: false,
          }
        })
        setCurrentUser({
          ...auth.user,
          usuario_password_temp: false
        })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getUsuario()
    }
  }

  useEffect(() => {
    getUsuario()
    getRoles()
  }, [getUsuario, getRoles])

  useEffect(() => {
    setModal(Boolean(auth.user.usuario_password_temp))

    if (usuario.temp_password) {
      setModal(true)
    }

    if (usuario.id_rol !== ROLES.ADMIN) {
      setUpdating(false)
    }
  }, [auth, usuario])

  return (
    <>
      <ResetPasswordModal
        show={modal}
        onHide={() => setModal(false)}
        onSubmit={(data) => {
          setModal(false)
          updatePassword(data)
        }}
        usuario={usuario}
      />

      <DetailContainer
        title={
          <>
            <p className={`${!usuario?.fecha_ultimo_login ? 'm-0' : ''} mt-4`}>{`Detalle de usuario #${id}${usuario?.nombre ? ` - ${usuario.nombre}${usuario?.apellido ? ` ${usuario.apellido}` : ''}` : ''}`}</p>
            {usuario?.fecha_ultimo_login && <p className='text-muted fs-4 m-0'>Últ. inicio sesión: {moment(usuario.fecha_ultimo_login).format('DD/MM/YYYY HH:mm:ss')}</p>}
          </>
        }
        buttons={<Actions usuario={usuario} updating={updating} handleUpdating={setUpdating} submitRef={submitRef} cancelRef={cancelRef} />}
      >
        <Formik
          initialValues={usuario}
          validationSchema={usuarioDetalleSchema}
          onSubmit={updateUsuario}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form>
                <div className='row my-8'>
                  <div className='col'>
                    <InputBS
                      id='nombre'
                      name='nombre'
                      type='text'
                      label='Nombre'
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>

                  <div className='col'>
                    <InputBS
                      id='apellido'
                      name='apellido'
                      type='text'
                      label='Apellido'
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>

                  <div className='col'>
                    <SelectField
                      id='id_rol'
                      name='id_rol'
                      label='Rol'
                      options={roles}
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-4'>
                    <InputBS
                      id='email'
                      name='email'
                      type='email'
                      label='Email'
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>

                  <div className='col-4'>
                    <InputBS
                      id='telefono_movil'
                      name='telefono_movil'
                      type='text'
                      label='Celular'
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-2'>
                    <SelectField
                      id='activo'
                      name='activo'
                      label='Activo'
                      options={[
                        { value: 0, label: 'No' },
                        { value: 1, label: 'Sí' },
                      ]}
                      disabled={
                        !updating
                        // || usuario.id_rol == ROLES.ADMIN
                      }
                    />
                  </div>
                </div>

                <div className='separator my-8' />

                <div className='text-end'>
                  {
                    updating &&
                    // usuario.id_rol !== ROLES.ADMIN &&
                      <>
                        <Button
                          ref={cancelRef}
                          type='button'
                          variant='secondary'
                          onClick={() => {
                            setUpdating(false)
                            handleReset()
                          }}
                          className='me-3'
                          disabled={loading}
                        >
                          <i className='bi bi-slash-circle' />
                          Cancelar
                        </Button>

                        <Button
                          ref={submitRef}
                          type='submit'
                          variant='primary'
                          disabled={loading || loadingR}
                        >
                          <i className='bi bi-save me-2' />
                          Guardar
                        </Button>
                      </>
                  }
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default UsuarioDetalle