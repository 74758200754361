import * as Yup from 'yup'

const usuarioInitialValues = {
	id: '',
	nombre: '',
	apellido: '',
	email: '',
	telefono_movil: '',
	activo: 1,
	id_rol: '',
	rol_nombre: '',
	fecha_ultimo_login: '',
}

const usuarioSchema = Yup.object().shape({
  nombre: Yup.string().required('* Ingrese el nombre del usuario'),
  apellido: Yup.string(),
  id_rol: Yup.number().required('* Seleccione un rol para el usuario'),
  email: Yup.string().email('* Ingrese un email válido').required('* Ingrese un email para el usuario'),
	password: Yup.string()
		.required('* Ingrese una contraseña')
		.min(8, '* La contraseña debe tener al menos 8 caracteres')
		.matches(/\d/, '* La contraseña debe contener al menos un número')
		.matches(/[A-Z]/, '* La contraseña debe contener al menos una mayúscula'),
	password_confirm: Yup.string()
		.required('* Confirme la contraseña'),
  telefono_movil: Yup.string(),
})

const usuarioDetalleSchema = Yup.object().shape({
	nombre: Yup.string().required('* Ingrese el nombre del usuario'),
	apellido: Yup.string(),
	id_rol: Yup.number().required('* Seleccione un rol para el usuario'),
	email: Yup.string().email('* Ingrese un email válido').required('* Ingrese un email para el usuario'),
	telefono_movil: Yup.string(),
	activo: Yup.number()
})

const usuarioAdapter = (usuario) => {
	let values = {
		...usuario,
		apellido: usuario?.apellido || '',
		telefono_movil: usuario?.telefono_movil || '',
		fecha_ultimo_login: usuario?.fecha_ultimo_login || '',
	}

	return values
}

const updateUsuarioAdapter = (usuario) => {
	let values = {
		...usuario,
		telefono_movil: usuario?.telefono_movil || '',
	}

	delete values.fecha_ultimo_login;

	return values
}

export {
	usuarioInitialValues,
  usuarioSchema,
	usuarioDetalleSchema,
	usuarioAdapter,
	updateUsuarioAdapter,
}