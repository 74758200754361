import * as Yup from 'yup'
import moment from 'moment'

import { ESTADO_TRATAMIENTO } from 'interfaces'

const today = moment(new Date().toISOString()).format('YYYY-MM-DD')

const tratamiento01InitialValues = {
  id: '',
  id_tratamiento: '',
  fecha_inicio: '',
  id_estadificacion_inicial: '',
  tratamiento_inicial: '',
  tuvo_cirugia: false,
  cirugia_fecha: '',
  cod_prestacion_nomenclador: '',
  cirugia_descripcion: '',
  tuvo_rayos: false,
  rayos_dosis: '',
  rayos_zona: '',
  sistemico_inicial_tipo: [],
  sistemico_inicial_proposito: '',
  sistemico_inicial: '',
  esquema_realizado_inicial_observaciones: '',
  respuesta_inicial: '',
  fecha_actual: today,
  id_estadificacion_actual: '',
  esquema_realizado_actual_observaciones: '',
  justificacion: '',
  receptores_hormonales: '',
  her2n: '',
  tratamiento_hormonal_previo: '',
  resultados_esperados: '',
}

const tratamientoEventoInitialValues = {
  id: '',
  fecha_creacion: '',
  observacion: '',
  id_estado_tratamiento: '',
  estado_tratamiento_nombre: '',
  id_tratamiento: '',
  id_usuario: '',
  usuario_nombre: '',
}

const tratamientoInitialValues = {
  id_tipo_tratamiento: '',
  id_financiador: '',
  id_laboratorio: '',
  id_paciente: '',
  talla: '',
  peso: '',
  id_patologia: '',
  id_cie10_cabecera: '',
  cie10_cabecera_descripcion: '',
  id_cie10_detalle: '',
  cie10_detalle_descripcion: '',
  fecha_inicio_enfermedad: '',
  fecha_creacion: new Date().toISOString().split('T')[0],
}

const detalleTratamientoInitialValues = {
  id_tipo_tratamiento: '',
  id_financiador: '',
  id_laboratorio: '',
  id_paciente: '',
  talla: '',
  peso: '',
  id_cie10_cabecera: '',
  cie10_cabecera_descripcion: '',
  id_cie10_detalle: '',
  cie10_detalle_descripcion: '',
  id_patologia: '',
  fecha_creacion: '',
  fecha_inicio_enfermedad: '',
  tratamiento_0: tratamiento01InitialValues,
  tratamiento_evento: tratamientoEventoInitialValues,
}

const seguimientoInitialValues = {
  fecha_creacion: today,
  fecha_inicio: '',
  fecha_finalizacion: '',
  ciclos: '',
  respuesta: '',
  continua_tratamiento: 0,
  proximo_control: '',
  mismo_esquema: 0,
  id_esquema_realizado: '',
  observacion: '',
  esquema_realizado: {
    detalle: [],
    observaciones: '',
  },
}

const tratamiento01Schema = Yup.object().shape({
  id: Yup.string(),
  id_tratamiento: Yup.string(),
  fecha_inicio: Yup.string(),
    // .required('* Seleccione una fecha de inicio'),
  id_estadificacion_inicial: Yup.number(),
    // .required('* Seleccione una estadificación inicial'),
  tratamiento_inicial: Yup.string(),
  tuvo_cirugia: Yup.boolean(),
  cirugia_fecha: Yup.string(),
  cod_prestacion_nomenclador: Yup.string(),
  cirugia_descripcion: Yup.string(),
  tuvo_rayos: Yup.boolean(),
  rayos_dosis: Yup.string(),
  rayos_zona: Yup.string(),
  sistemico_inicial: Yup.string(),
  esquema_realizado_inicial_observaciones: Yup.string(),
  respuesta_inicial: Yup.string(),
  fecha_actual: Yup.string(),
  id_estadificacion_actual: Yup.string(),
  esquema_realizado_actual_observaciones: Yup.string(),
  justificacion: Yup.string(),
  receptores_hormonales: Yup.string(),
  her2n: Yup.string(),
  tratamiento_hormonal_previo: Yup.string(),
  resultados_esperados: Yup.string(),
});

const tratamientoEventoSchema = Yup.object().shape({
  id: Yup.string(),
  fecha_creacion: Yup.string(),
  observacion: Yup.string(),
  id_estado_tratamiento: Yup.string(),
  estado_tratamiento_nombre: Yup.string(),
  id_tratamiento: Yup.string(),
  id_usuario: Yup.string(),
  usuario_nombre: Yup.string(),
});

const tratamientoSchema = Yup.object().shape({
  // id_tipo_tratamiento: Yup.number().required('* Seleccione un tipo de tratamiento'),
  id_financiador: Yup.number(),
  id_laboratorio: Yup.number(),
  id_paciente: Yup.number(),
  talle: Yup.number(),
  peso: Yup.number(),
  id_cie10_cabecera: Yup.string().required('* Seleccione un diagnóstico'),
  id_cie10_detalle: Yup.string(),
  id_patologia: Yup.number().required('* Seleccione una patología'),
  fecha_creacion: Yup.string(),
  // fecha_inicio_enfermedad: Yup.string(),
})

const tratamientoUpdateSchema = Yup.object().shape({
  id_tipo_tratamiento: Yup.number().required('* Seleccione un tipo de tratamiento'),
  id_financiador: Yup.number(),
  id_laboratorio: Yup.number(),
  id_paciente: Yup.number(),
  talla: Yup.string(),
  peso: Yup.string(),
  id_cie10_cabecera: Yup.string().required('* Seleccione un diagnóstico'),
  id_cie10_detalle: Yup.string(),
  id_patologia: Yup.number().required('* Seleccione una patología'),
  fecha_inicio_enfermedad: Yup.string(),
  tratamiento_01: tratamiento01Schema,
  // tratamiento_evento: tratamientoEventoAdapter
})

const tratamiento01Adapter = (tratamiento_01) => {
  let values = {
    ...tratamiento_01,
    id: tratamiento_01?.id || '',
    id_tratamiento: tratamiento_01?.id_tratamiento || '',
    fecha_inicio: tratamiento_01?.fecha_inicio ? moment(tratamiento_01.fecha_inicio).format('YYYY-MM-DD') : '',
    id_estadificacion_inicial: tratamiento_01?.id_estadificacion_inicial || '',
    tratamiento_inicial: tratamiento_01?.tratamiento_inicial || '',
    tuvo_cirugia: Boolean(tratamiento_01?.tuvo_cirugia),
    cirugia_fecha: tratamiento_01?.cirugia_fecha ? moment(tratamiento_01.cirugia_fecha).format('YYYY-MM-DD') : '',
    cod_prestacion_nomenclador: tratamiento_01?.cod_prestacion_nomenclador || '',
    cirugia_descripcion: tratamiento_01?.cirugia_descripcion || '',
    tuvo_rayos: Boolean(tratamiento_01?.tuvo_rayos),
    rayos_dosis: tratamiento_01?.rayos_dosis || '',
    rayos_zona: tratamiento_01?.rayos_zona || '',
    sistemico_inicial_tipo: tratamiento_01?.sistemico_inicial_tipo ? tratamiento_01.sistemico_inicial_tipo.split(',').map(Number) : [],
    sistemico_inicial_proposito: tratamiento_01?.sistemico_inicial_proposito ? tratamiento_01.sistemico_inicial_proposito : '',
    sistemico_inicial: tratamiento_01?.sistemico_inicial || '',
    esquema_realizado_inicial_observaciones: tratamiento_01?.esquema_realizado_inicial_observaciones || '',
    respuesta_inicial: tratamiento_01?.respuesta_inicial || '',
    fecha_actual: tratamiento_01?.id ? tratamiento_01?.fecha_actual ? moment(tratamiento_01.fecha_actual).format('YYYY-MM-DD') : today : today,
    id_estadificacion_actual: tratamiento_01?.id_estadificacion_actual || '',
    esquema_realizado_actual_observaciones: tratamiento_01?.esquema_realizado_actual_observaciones || '',
    justificacion: tratamiento_01?.justificacion || '',
    receptores_hormonales: tratamiento_01?.receptores_hormonales || '',
    her2n: tratamiento_01?.her2n || '',
    tratamiento_hormonal_previo: tratamiento_01?.tratamiento_hormonal_previo || '',
    resultados_esperados: tratamiento_01?.resultados_esperados || '',
  }

  return values

}
const updateTratamiento01Adapter = (tratamiento_01) => {
  let values = {
    ...tratamiento_01,
    id: tratamiento_01?.id || '',
    id_tratamiento: tratamiento_01?.id_tratamiento || '',
    fecha_inicio: tratamiento_01?.fecha_inicio ? moment(tratamiento_01.fecha_inicio).format('YYYY-MM-DD') : '',
    id_estadificacion_inicial: tratamiento_01?.id_estadificacion_inicial || '',
    tratamiento_inicial: tratamiento_01?.tratamiento_inicial || '',
    tuvo_cirugia: Boolean(tratamiento_01?.tuvo_cirugia),
    cirugia_fecha: tratamiento_01?.cirugia_fecha ? moment(tratamiento_01.cirugia_fecha).format('YYYY-MM-DD') : '',
    cod_prestacion_nomenclador: tratamiento_01?.cod_prestacion_nomenclador || '',
    cirugia_descripcion: tratamiento_01?.cirugia_descripcion || '',
    tuvo_rayos: Boolean(tratamiento_01?.tuvo_rayos),
    rayos_dosis: tratamiento_01?.rayos_dosis || '',
    rayos_zona: tratamiento_01?.rayos_zona || '',
    sistemico_inicial_tipo: tratamiento_01?.sistemico_inicial_tipo ? tratamiento_01.sistemico_inicial_tipo : [],
    sistemico_inicial_proposito: tratamiento_01?.sistemico_inicial_proposito ? tratamiento_01.sistemico_inicial_proposito : '',
    sistemico_inicial: tratamiento_01?.sistemico_inicial || '',
    esquema_realizado_inicial_observaciones: tratamiento_01?.esquema_realizado_inicial_observaciones || '',
    respuesta_inicial: tratamiento_01?.respuesta_inicial || '',
    fecha_actual: tratamiento_01?.id ? tratamiento_01?.fecha_actual ? moment(tratamiento_01.fecha_actual).format('YYYY-MM-DD') : today : today,
    id_estadificacion_actual: tratamiento_01?.id_estadificacion_actual || '',
    esquema_realizado_actual_observaciones: tratamiento_01?.esquema_realizado_actual_observaciones || '',
    justificacion: tratamiento_01?.justificacion || '',
    receptores_hormonales: tratamiento_01?.receptores_hormonales || '',
    her2n: tratamiento_01?.her2n || '',
    tratamiento_hormonal_previo: tratamiento_01?.tratamiento_hormonal_previo || '',
    resultados_esperados: tratamiento_01?.resultados_esperados || '',
  }

  delete values.prestacion_nomenclador_descripcion

  return values
}

const tratamientoEventoAdapter = (tratamiento_evento) => {
  let values = {
    ...tratamiento_evento,
    id: tratamiento_evento?.id || '',
    fecha_creacion: tratamiento_evento?.fecha_creacion ? moment(tratamiento_evento.fecha_creacion).format('YYYY-MM-DD') : '',
    observacion: tratamiento_evento?.observacion || '',
    id_estado_tratamiento: tratamiento_evento?.id_estado_tratamiento || '',
    estado_tratamiento_label: tratamiento_evento?.estado_tratamiento_label || '',
    id_tratamiento: tratamiento_evento?.id_tratamiento || '',
    id_usuario: tratamiento_evento?.id_isuario || '',
    usuario_nombre: tratamiento_evento?.usuario_nombre || '',
  }

  return values
}

const tratamientoAdapter = (tratamiento) => {
  let values = {
    ...tratamiento,
    id_financiador: tratamiento?.id_financiador || '',
    id_laboratorio: tratamiento?.id_laboratorio || '',
    id_paciente: tratamiento?.id_paciente || '',
    talla: tratamiento?.talla || '',
    peso: tratamiento?.peso || '',
    fecha_inicio_enfermedad: tratamiento?.fecha_inicio_enfermedad ? moment(tratamiento?.fecha_inicio_enfermedad).format('YYYY-MM-DD') : '',
    fecha_creacion: tratamiento?.fecha_creacion ? moment(tratamiento?.fecha_creacion).format('YYYY-MM-DD') : '',
    tratamiento_01: tratamiento01Adapter(tratamiento.tratamiento_01),
    tratamiento_evento: tratamientoEventoAdapter(tratamiento.tratamiento_evento),
  }

  return values
}

const updateTratamientoAdapter = (tratamiento) => {
  let values = {
    ...tratamiento,
    fecha_inicio_enfermedad: tratamiento?.fecha_inicio_enfermedad ? moment(tratamiento?.fecha_inicio_enfermedad).format('YYYY-MM-DD') : '',
    fecha_creacion: tratamiento?.fecha_creacion ? moment(tratamiento?.fecha_creacion).format('YYYY-MM-DD') : '',
    tratamiento_01: updateTratamiento01Adapter(tratamiento.tratamiento_01),
    tratamiento_evento: tratamientoEventoAdapter(tratamiento.tratamiento_evento),
  }

  delete values?.usuario_creacion
  delete values?.financiador_nombre
  delete values?.laboratorio_nombre
  delete values.fecha_creacion
  delete values.cie10_cabecera_descripcion
  delete values.cie10_detalle_descripcion
  delete values.tipo_tratamiento_label
  delete values.paciente_nombre_completo
  delete values.diagnostico_codigo
  delete values.patologia_label
  delete values.label_color

  return values
}

const statusLabelColor = (status) => {
  switch (status) {
    case ESTADO_TRATAMIENTO.BORRADOR:
      return 'estado-gris'
    case ESTADO_TRATAMIENTO.PENDIENTE_AUDITOR:
      return 'estado-amarillo'
    case ESTADO_TRATAMIENTO.PENDIENTE_MEDICO:
      return 'estado-naranja'
    case ESTADO_TRATAMIENTO.EN_PROGRESO:
      return 'estado-verde'
    case ESTADO_TRATAMIENTO.FINALIZADO_OK:
      return 'estado-celeste'
    case ESTADO_TRATAMIENTO.FINALIZADO_NO_OK:
      return 'estado-rojo'
    default:
      return 'estado-gris'
  }
}

const respuestas = [
  { value: 'RESPUESTA_COMPLETA', label: 'Respuesta completa' },
  { value: 'RESPUESTA_PARCIAL', label: 'Respuesta parcial' },
  { value: 'ENFERMEDAD_ESTABLE', label: 'Enfermedad estable' },
  { value: 'PROGRESION_DE_LA_ENFERMEDAD', label: 'Progresión de la enfermedad' },
]

export {
  tratamientoInitialValues,
  detalleTratamientoInitialValues,
  tratamientoSchema,
  tratamientoUpdateSchema,
  tratamientoAdapter,
  updateTratamientoAdapter,
  statusLabelColor,
  seguimientoInitialValues,
  respuestas,
}