import service from 'services/service'

const PATH = {
  estadificaciones: '/estadificaciones',
}

export class Estadificaciones {
  static getEstadificaciones = async (params) => {
    const response = await service.get(`${PATH.estadificaciones}`, {
      params,
    })
    return response
  }

  static getEstadificacion = async (id, params) => {
    const response = await service.get(`${PATH.estadificaciones}/${id}`, {
      params
    })
    return response
  }

  static addEstadificacion = async (data) => {
    const response = await service.post(`${PATH.estadificaciones}`, data)
    return response
  }

  static updateEstadificacion = async (id, data) => {
    const response = await service.patch(`${PATH.estadificaciones}/${id}`, data)
    return response
  }
}