import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useTratamientos, useFinanciadores, useLaboratorios, usePacientes, usePatologias, useDiagnosticos } from 'hooks'

import { SelectField, InputBS } from 'components'

import { ROLES, ESTADO_TRATAMIENTO } from 'interfaces'

const GeneralTab = ({ updating }) => {
  const { auth } = useAuth()
  const { values, setFieldValue } = useFormikContext()

  // const { loading, handleGetTiposTratamiento } = useTratamientos()
  const { loading: loadingF, handleGetFinanciadores } = useFinanciadores()
  const { loading: loadingL, handleGetLaboratorios } = useLaboratorios()
  const { loading: loadingP, handleGetPacientes } = usePacientes()
  const { loading: loadingPa, handleGetPatologias } = usePatologias()
  const { loading: loadingD, handleGetCie10Cabeceras, handleGetCie10Detalles } = useDiagnosticos()
  // const [tipos, setTipos] = useState([])
  const [financiadores, setFinanciadores] = useState([])
  const [laboratorios, setLaboratorios] = useState([])
  const [pacientes, setPacientes] = useState([])
  const [cie10Cabeceras, setCie10Cabeceras] = useState([])
  const [cie10Detalles, setCie10Detalles] = useState([])
  const [patologias, setPatologias] = useState([])

  // const getTiposTratamiento = useCallback(async () => {
  //   try {
  //     let params = {
  //       sortOrder: 'ASC',
  //       activo: 1,
  //     }
  //     const response = await handleGetTiposTratamiento(params)
  //     const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

  //     setTipos(data)
  //   } catch (error) {
  //     toast.error(error.message)
  //     setTipos([])
  //   }
  // }, [handleGetTiposTratamiento])

  const getFinanciadores = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetFinanciadores(params)
      const data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      setFinanciadores(data)
    } catch (error) {
      toast.error(error.message)
      setFinanciadores([])
    }
  }, [handleGetFinanciadores])

  const getLaboratorios = useCallback(async () => {
    try {
      let params = {
        activo: 1,
      }
      const response = await handleGetLaboratorios(params)
      const data = response.data.result.map(d => ({ value: d.id, label: `${d.nombre}${d?.apellido ? ` ${d.apellido}` : ''}`}))

      setLaboratorios(data)
    } catch (error) {
      toast.error(error.message)
      setLaboratorios([])
    }
  }, [handleGetLaboratorios])

  const getPacientes = async (query) => {
    try {
      let params = {
        text: query
      }
      const response = await handleGetPacientes(params)
      setPacientes(response.data.result)
    } catch (error) {
      toast.error(error.message)
      setPacientes([])
    }
  }

  const getCie10Cabeceras = async (query) => {
    try {
      let params = {
        text: query,
        sortOrder: 'ASC',
      }
      const response = await handleGetCie10Cabeceras(params)
      setCie10Cabeceras(response.data.result)
    } catch (error) {
      setCie10Cabeceras([])
      toast.error(error.message)
    }
  }

  const getCie10Detalles = useCallback(async (id_cie10_cabecera) => {
    try {
      let params = {
        cod_3: id_cie10_cabecera,
        sortOrder: 'ASC',
      }
      const response = await handleGetCie10Detalles(params)
      const data = response.data.result.map(d => ({ value: d.cod_4, label: `${d.cod_4} - ${d.descripcion_4}`}))

      setCie10Detalles(data)
    } catch (error) {
      setCie10Detalles([])
      toast.error(error.message)
    }
  }, [handleGetCie10Detalles])

  const getPatologias = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
        activo: 1,
      }
      const response = await handleGetPatologias(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setPatologias(data)
    } catch (error) {
      toast.error(error.message)
      setPatologias([])
    }
  }, [handleGetPatologias])

  useEffect(() => {
    // getTiposTratamiento()
    getFinanciadores()
    getLaboratorios()
    getPatologias()
  }, [/* getTiposTratamiento, */getFinanciadores, getLaboratorios, getPatologias])

  useEffect(() => {
    getCie10Detalles(values.id_cie10_cabecera)
  }, [values.id_cie10_cabecera])

  return (
    <>
      <div className='row mb-8'>
        {/* <div className='col-4'>
          <SelectField
            id='id_tipo_tratamiento'
            name='id_tipo_tratamiento'
            label='Tipo tratamiento'
            options={tipos}
            placeholder='Seleccionar tipo tratamiento'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div> */}

        <div className='col'>
          <SelectField
            id='id_financiador'
            name='id_financiador'
            label='Financiador'
            options={financiadores}
            placeholder='Seleccione un financiador'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <SelectField
            id='id_laboratorio'
            name='id_laboratorio'
            label='Laboratorio'
            options={laboratorios}
            placeholder='Seleccione un laboratorio'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-6'>
          <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Paciente</FormLabel>
          <AsyncTypeahead
            id='busquedaPaciente'
            isLoading={loadingP}
            minLength={3}
            labelKey={(p) => `${p.documento} - ${p.nombre} ${p.apellido}`}
            onSearch={getPacientes}
            options={pacientes}
            filterBy={() => true}
            prompText='Escribe para buscar un paciente'
            searchText='Buscando...'
            placeholder={values?.id_paciente ? values.paciente_nombre_completo : 'Escribe para buscar un paciente'}
            defaultInputValue={values?.id_paciente ? values.paciente_nombre_completo : ''}
            onChange={(e) => {
              if (e && e.length > 0) {
                setFieldValue('id_paciente', e[0].id)
                setFieldValue('paciente_nombre_completo', `${e[0].documento} - ${e[0].nombre} ${e[0].apellido}`)
              } else {
                setFieldValue('id_paciente', '')
                setFieldValue('paciente_nombre_completo', '')
              }
            }}
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <InputBS
            id='talla'
            name='talla'
            type='number'
            label='Talla (cm)'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <InputBS
            id='peso'
            name='peso'
            type='number'
            label='Peso (kg)'
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <SelectField
            id='id_patologia'
            name='id_patologia'
            label='Patología'
            options={patologias}
            placeholder='Seleccionar patología'
            disabled={
              loadingP &&
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Diagnóstico</FormLabel>
          <AsyncTypeahead
            id='busquedaCie10Cabecera'
            isLoading={loadingD}
            minLength={3}
            labelKey={(d) => `${d.cod_3} - ${d.descripcion_3}`}
            onSearch={getCie10Cabeceras}
            options={cie10Cabeceras}
            filterBy={() => true}
            prompText='Escribe para buscar un diagnóstico'
            searchText='Buscando...'
            placeholder={values?.id_cie10_cabecera ? `${values.id_cie10_cabecera} - ${values.cie10_cabecera_descripcion}` : 'Escribe para buscar un diagnóstico'}
            defaultInputValue={values?.id_cie10_cabecera ? `${values.id_cie10_cabecera} - ${values.cie10_cabecera_descripcion}` : ''}
            onChange={(e) => {
              if (e && e.length > 0) {
                setFieldValue('id_cie10_cabecera', e[0].cod_3)
                setFieldValue('cie10_cabecera_descripcion', `${e[0].cod_3} ${e[0].descripcion_3}`)
                setFieldValue('id_cie10_detalle', '')
                getCie10Detalles(e[0].cod_3)
              } else {
                setFieldValue('id_cie10_cabecera', '')
                setFieldValue('cie10_cabecera_descripcion', '')
                setFieldValue('id_cie10_detalle', '')
              }
            }}
            disabled={
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>

        <div className='col'>
          <SelectField
            id='id_cie10_detalle'
            name='id_cie10_detalle'
            label='Detalle'
            options={cie10Detalles}
            placeholder='Seleccionar detalle'
            disabled={
              loadingD &&
              cie10Detalles.length == 0 &&
              !auth.user.usuario_roles.some(r => (r.id == ROLES.ADMIN || r.id == ROLES.MEDICO)) ||
              values?.tratamiento_evento?.id_estado_tratamiento !== ESTADO_TRATAMIENTO.BORRADOR ||
              !updating
            }
          />
        </div>
      </div>

      <div className='row mt-8'>
        <div className='col-6'></div>

        <div className='col-3'>
          <InputBS
            id='fecha_inicio_enfermedad'
            name='fecha_inicio_enfermedad'
            type='date'
            label='Inicio enfermedad'
            disabled
          />
        </div>

        <div className='col-3'>
          <InputBS
            id='fecha_creacion'
            name='fecha_creacion'
            type='date'
            label='Fecha carga'
            disabled
          />
        </div>
      </div>
    </>
  )
}

export default GeneralTab