import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useClickOutside, useFinanciadores } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './columns'

import routes from 'app/routing/routes'

const initialQuery = {
	pageNumber: 1,
	pageSize: 10,
}

const Financiadores = () => {
	const [data, setData] = useState([])
	const { COLUMNS } = useGetColumns()

	const [query, setQuery] = useState(initialQuery)
	const [totalCount, setTotalCount] = useState(0)

	const { handleGetFinanciadores } = useFinanciadores()

	const getFinanciadores = useCallback(async () => {
		try {
			let params = {}

			if (query && query.pageNumber) {
				params = {
					...params,
					...query,
				}
			}

			const response = await handleGetFinanciadores(params)
			setData(response.data.result)
			setTotalCount(response.data.metadata.count)
		} catch (error) {
			toast.error(error.message)
			setData([])
			setTotalCount(0)
		}
	}, [handleGetFinanciadores, query])

	/* Menu contextual */
	const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.FINANCIADORES}/${currentRow?.id}`)
    })

    return actions
  }
  /* Fin menu contextual */

	const columns = useMemo(() => {
    const baseColumns = [
			...COLUMNS,
			{
				Header: '',
				id: 'actions',
				className:'col-icons',
				accessor: (row) =>
					<>
						<i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
					</>
			}
		]

		return baseColumns
	}, [COLUMNS, data])

	const paginationOptions = {
		totalSize: totalCount,
		obQuery: query,
		setObQuery: setQuery,
	}

	useEffect(() => {
		getFinanciadores()
	}, [getFinanciadores])

	return (
		<>
			<TableContainer
				title={'Financiadores'}
				goTo={routes.FINANCIADORES_NEW}
				// filter={}
			>
				<CustomTable columns={columns} data={data} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
			</TableContainer>

			<ContextMenu
				showContextMenu={showContextMenu}
				setShowContextMenu={setShowContextMenu}
				actions={availableActions()}
				currentPos={currentPos}
				ref={contextRef}
			/>
		</>
	)
}

export default Financiadores