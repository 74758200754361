import axios from "axios";
import { AuthModel } from 'app/modules/auth/core/_models'
import { useAuth } from "app/modules/auth";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel

    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});

axiosInstance.interceptors.request.use(
  request => {
    const auth = getAuth()
    if (request.headers && auth && auth.token) {
      request.headers.Authorization = `Bearer ${auth.token}`
    }

    return request;
  },
  error => {
    console.log('request error: ', error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 403) {
      window.location.href = '/login';
    } else if (error.response && error.response.data) {
      console.log('response error: ', error.response.data);
      return Promise.reject(error.response.data);
    } else {
      console.log('response error: ', error);
      return Promise.reject(error);
    }
  },
);

const service = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  patch: axiosInstance.patch,
  delete: axiosInstance.delete,
};

export default service;