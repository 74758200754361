import { useEffect, useState } from 'react'
import { useTable, useGlobalFilter, usePagination, useSortBy, useRowSelect } from 'react-table'
import clsx from 'clsx';

import TablePagination from 'components/TablePagination'
import { Tooltip } from 'components'
import './styles.sass'

const CustomTable = ({ columns, data, paginationOptions, className, queryMD, setQueryMD, handleContextMenu = () => {}, responsive = true, handleSelectedRows = () => {}}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    toggleAllRowsSelected,
    getToggleAllRowsSelectedProps,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      manualSortBy: true,
      defaultCanSort: false,
    },
    useGlobalFilter, useSortBy, usePagination, useRowSelect
  )

  const [sortState, setSortState] = useState({ sort:undefined, order:'desc' })

  const isSelectedForSorting = (id) => {
    return sortState.sort && sortState.sort === id
  }

  const sortColumn = (id) => {
    if (queryMD && setQueryMD) {
      if (sortState.order == 'asc') {
        setSortState({ sort: id, order: 'desc' })
        setQueryMD({ ...queryMD, sortField: id, sortOrder: 'desc' })
      } else {
        setSortState({ sort: id, order: 'asc' })
        setQueryMD({ ...queryMD, sortField: id, sortOrder: 'asc' })
      }
    }
  }

  useEffect(() => {
    handleSelectedRows(selectedRowIds, selectedFlatRows);
  }, [selectedRowIds, handleSelectedRows]);

  useEffect(()=>{
  }, [sortState])

  return (
    <div className={responsive ? "table-responsive" : ''}>
      <table className={`CustomTable table table-striped gy-7 gs-7 ${className || ''}`}  {...getTableProps}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className="fs-6 text-muted border-bottom-2 border-gray-200" {...headerGroup.getHeaderGroupProps()} style={{ fontWeight: "500" }}>
              {headerGroup.headers.map(column =>
                {
                  return (
                    (column).hasOrder
                      ?
                        <th {...column.getHeaderProps()}
                          className={clsx( isSelectedForSorting(column.id) && sortState.order !== undefined && `table-sort-${sortState.order}` )}
                          style={{cursor: 'pointer'}}
                          onClick={()=>sortColumn(column.id)}
                        >
                          {column.render('Header')}
                        </th>
                      :
                        <th {...column.getHeaderProps()} >
                          {column.render('Header')}
                        </th>
                  )
                }
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {data.length === 0 && <tr><td className='text-italic text-gray-400' colSpan={columns.length}>No hay información</td></tr>}

          {rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onContextMenu={(e) => { handleContextMenu(e, row.original)}}
                style={{ verticalAlign: "middle" }}
              >
                {row.cells.map((cell, index) => (
                  <td {...cell.getCellProps({ className: cell.column.className })}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      <TablePagination paginationOptions={paginationOptions || {}} />
    </div>
  )
}

export default CustomTable